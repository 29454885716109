import './assets/styles/TopLoader.scss';

import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  isHidden?: boolean;
};

/**
 * TopLoader
 *
 * @param {string} className
 * @param {boolean} hidden
 * @param {object} contextProps
 * @constructor
 */
const TopLoader: React.FC<Props> = ({ className, isHidden, ...contextProps }) => (
  <div className={classNames('c-top-loader', className)} {...contextProps}>
    {!isHidden && <div className="indeterminate" />}
  </div>
);

TopLoader.defaultProps = {
  isHidden: false,
};

export default TopLoader;
