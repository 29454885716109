import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Sdu CRM hub API service
 */
export default class SduCRMHubApi extends APIEndpoint {
  /**
   * Get settings
   *
   */
  getSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('sdu_crm_hub/admin');
  }

  /**
   * Set settings
   *
   * @param {object} values
   */
  setSettings(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sdu_crm_hub/admin/settings',
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Get Exact connect url
   */
  getExactConnectUrl() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sdu_crm_hub/admin/exact_auth_url',
    );
  }
}
