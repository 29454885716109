import type { Location, Path, To } from 'react-router';
import LocalStorageService from 'services/LocalStorageService';

const AUTHENTICATE_REDIRECT = 'authenticate_redirect';

const blacklistedUrls = ['/logout', '/auth'];

/**
 * Save current location to the location storage for later.
 *
 * @param {any} locationState
 */
export const authRedirectSaveLocation = (locationState: any) => {
  if (locationState) {
    LocalStorageService.setItem(
      AUTHENTICATE_REDIRECT,
      JSON.stringify({
        hash: locationState.hash,
        pathname: locationState.pathname,
        search: locationState.search,
      }),
    );
  }
};

/**
 * Get saved redirect location from location storage
 *
 * @param {Location} location
 * @returns {To}
 */
export const authRedirectGetLocation = (location: Location): To => {
  if (location.search) {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('to')) {
      return authCheckUrlWithBlackList(
        {
          hash: undefined,
          pathname: searchParams.get('to') ?? undefined,
          search: searchParams.get('to_query') ?? undefined,
        },
        blacklistedUrls,
      );
    }
  }

  const redirectUrl = JSON.parse(
    LocalStorageService.getItem(AUTHENTICATE_REDIRECT, JSON.stringify({ pathname: '/' })),
  );
  authRedirectRemoveState();

  return authCheckUrlWithBlackList(redirectUrl, blacklistedUrls);
};

/**
 * Check if the url is not from a blacklisted url
 *
 * @param {To} redirectUrl
 * @param {string[]} blacklist
 * @returns {To}
 */
const authCheckUrlWithBlackList = (redirectUrl: To, blacklist: string[]): To => {
  const retValue = redirectUrl as Partial<Path>;
  const pathname = retValue.pathname;

  if (pathname) {
    if (!pathname.startsWith('/')) {
      retValue.pathname = '/';
      retValue.search = undefined;
    }
    if (pathname.startsWith('/')) {
      if (blacklist.filter((url) => pathname.startsWith(url)).length > 0) {
        retValue.pathname = '/';
        retValue.search = undefined;
      }
    }
  }

  return retValue;
};

/**
 * Remove redirect state from localstorage
 */
export const authRedirectRemoveState = () => {
  LocalStorageService.removeItem(AUTHENTICATE_REDIRECT); // remove when the use is authenticated correctly.
};
