import './assets/styles/Modal.scss';

import classNames from 'classnames';
import CloseIcon from 'components/icons/CloseIcon';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Portal } from 'react-portal';

let uniqueModalId = 1;

type HeaderProps = {
  color?: string;
  title?: string | React.ReactNode;
  onClose?: () => void;
};

type Props = React.PropsWithChildren<{
  className?: string;
  header?: HeaderProps;
  footer?: React.ReactNode;
  sheet?: React.ReactNode;
  shouldUsePortal?: boolean;
  onSheetClick?: () => void;
}>;

/**
 * Modal component
 *
 * @param {object|boolean} header
 * @param {node} footer
 * @param {boolean} sheet
 * @param {node} children
 * @param {string} className
 * @param {boolean} shouldUsePortal
 * @param {function} onSheetClick
 * @param {object} contextProps
 * @return {*}
 * @constructor
 */
const Modal: React.FC<Props> = ({
  header,
  footer,
  sheet,
  children,
  className,
  shouldUsePortal,
  onSheetClick,
  ...contextProps
}) => {
  const [uniqueId] = useState(`react-modal-${uniqueModalId++}`);
  const { title, onClose } = header || {};
  const onOutsideModalClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const target: any = event.target; // make typescript happen by casting it to any. (should be improved.)
    const eventClassList = target.classList;
    if (onClose && eventClassList && eventClassList.contains('c-modal')) {
      onClose();
    }
  };

  // mount component/update
  useEffect(() => {
    const body: any = document.querySelector('BODY'); // same here.
    if (body) {
      body.style.overflow = 'hidden';
    }

    // dismount component
    return () => {
      body.style.overflow = '';
    };
  });

  const modalProps = {
    onClick: onOutsideModalClick,
  };

  const headerColor = header?.color || undefined;
  const modalRender = (
    <div
      className={classNames('c-modal', className, { 'c-modal--sheet-active': sheet })}
      {...contextProps}
      {...modalProps}
    >
      <Draggable handle={`#${uniqueId}`}>
        <div className="modal__dialog" role="dialog">
          {header && (
            <div className="dialog__header" id={uniqueId} style={{ backgroundColor: headerColor }}>
              <div className="header__title">{title}</div>
              {onClose && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div className="header__close" role="button" tabIndex={0} onClick={onClose}>
                  <CloseIcon size={16} />
                </div>
              )}
            </div>
          )}
          <div className="dialog__content">{children}</div>
          {footer && <div className="dialog__footer">{footer}</div>}
          {sheet && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="dialog__sheet" role="button" tabIndex={-1} onClick={onSheetClick} />
          )}
        </div>
      </Draggable>
    </div>
  );

  if (shouldUsePortal) {
    // @ts-ignore
    return <Portal>{modalRender}</Portal>;
  } else {
    return modalRender;
  }
};

Modal.defaultProps = {
  shouldUsePortal: false,
};

export default Modal;
