import type { SignalingSendRequestFormValues } from 'components/signaling/SignalingSendRequestForm';
import type { SignalingPredicateGroup } from 'entity/signaling/types';
import type { ApiFilter } from 'types/types';

import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

export default class SignalingApi extends APIEndpoint {
  getSignalingOverview(filters: ApiFilter[] = [], sort = '', page = 1, pageSize = 10) {
    const url = this.buildUrl('signaling', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  getSignalingDetails(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`signaling/${signalingId}`);
  }

  updateSignalingDetails(signalingId: string, signaling: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      signaling,
    );
  }

  updateSignalingTriggers(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/update_with_triggers`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  createSignaling(data: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'signaling',
      JSendRequest.REQUEST_METHOD_POST,
      data,
    );
  }

  archiveSignaling(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  unArchiveSignaling(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  deleteSignaling(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  getSignalingAuditLog(signalingId: string, page = 1) {
    const url = this.buildUrl(`signaling/${signalingId}/audit`, [], '', page, 25);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  archiveSignalingSignals(signalingId: string, signalingSignalIds: string[]) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        signalingSignalIds,
      },
    );
  }

  unArchiveSignalingSignals(signalingId: string, signalingSignalIds: string[]) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        signalingSignalIds,
      },
    );
  }

  archiveSignalingSignal(signalingId: string, signalingSignalId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/${signalingSignalId}/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  unArchiveSignalingSignal(signalingId: string, signalingSignalId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/${signalingSignalId}/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  getSignalingSignalOverview(
    signalingId: string,
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 10,
  ) {
    const url = this.buildUrl(`signaling/${signalingId}/signals`, filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  getSignalingSignalDetails(signalingId: string, signalingSignalId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/${signalingSignalId}`,
    );
  }

  updateSignalingSignalUpdate(
    signalingId: string,
    signalingSignalId: string,
    values: Record<string, any>,
  ) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/signals/${signalingSignalId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  getSignalingSignalAuditLog(signalingId: string, signalingSignalId: string, page = 1) {
    const url = this.buildUrl(
      `signaling/${signalingId}/signals/${signalingSignalId}/audit`,
      [],
      '',
      page,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Validate import file
   */
  validateImportFile(importFile: File) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      'signaling/validate_clients_import',
      importFile,
      {},
    );
  }

  /**
   * Import customer file to a signaling
   */
  importCustomerFile(signalingId: string, importFile: File) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      `signaling/${signalingId}/update_clients`,
      importFile,
      {},
    );
  }

  /**
   * Bulk update all predicate groups for the given signaling.
   */
  bulkUpdatePredicateGroups(
    signalingId: string,
    signalingPredicateGroups: SignalingPredicateGroup[],
  ) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/predicate_groups/bulk`,
      JSendRequest.REQUEST_METHOD_POST,
      { signalingPredicateGroups },
    );
  }

  /**
   * Retrieve all predicate groups that are attached to the given signaling.
   */
  getSignalingPredicateGroups(signalingId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/${signalingId}/predicate_groups`,
    );
  }

  /**
   * Search a signal globally
   */
  searchSignal(filters: ApiFilter[] = [], sort = '', page = 1, pageSize = 10) {
    const url = this.buildUrl('signaling/search_signal', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Get signaling for a client
   */
  getSignalingForClient(
    clientId: string,
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 10,
  ) {
    const url = this.buildUrl(`crm/clients/${clientId}/signaling`, filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  getSignalingVismaHierarchy() {
    const url = this.buildUrl('visma_hierarchy/');
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  getSignalingContactOverview(
    signalingId: string,
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 10,
  ) {
    const url = this.buildUrl(
      `signaling/${signalingId}/contact_persons`,
      filters,
      sort,
      page,
      pageSize,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  sendRequest(values: SignalingSendRequestFormValues) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/send_request`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  getStatistics(filters: ApiFilter[] = []) {
    const filterQuery = filters.length > 0 ? `?filter=${this.buildFilterQueryValue(filters)}` : '';
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `signaling/statistics${filterQuery}`,
    );
  }
}
