import React from 'react';

import { ReactComponent } from './assets/svg/CloseIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const CloseIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

CloseIcon.propTypes = IconComponent.propTypes;
CloseIcon.defaultProps = IconComponent.defaultProps;

export default CloseIcon;
