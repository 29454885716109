import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import React from 'react';

import TopLoader from './TopLoader';

export const FetchLoader: React.FC = () => {
  const fetchingCount = useIsFetching();
  const mutatingCount = useIsMutating();

  return <TopLoader isHidden={fetchingCount === 0 && mutatingCount === 0} />;
};
