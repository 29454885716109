import type { ApiFilter } from 'types/types';

import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

export default class TeamApi extends APIEndpoint {
  /**
   * Get teams
   *
   * @param {array} filters
   * @param {string} sort
   * @param {number} page
   * @param {number} limit
   * @returns {Promise}
   */
  getTeams(filters: ApiFilter[] = [], sort = '', page = 1, limit = 10) {
    const url = this.buildUrl('teams', filters, sort, page, limit);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Team details
   *
   * @param {string} teamId
   * @returns {Promise}
   */
  teamDetails(teamId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`teams/${teamId}`);
  }

  /**
   * Team audit log
   *
   * @param {string} teamId
   * @param {number} page
   * @param {number} limit
   */
  teamAuditLog(teamId: string, page = 1, limit = 10) {
    const url = this.buildUrl(`teams/${teamId}/audit`, [], '', page, limit);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Create team
   *
   * @param {object} values
   * @returns {Promise}
   */
  createTeam(values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'teams',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update team
   */
  updateTeam(teamId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete team
   */
  deleteTeam(teamId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get users from team
   */
  teamUsers(teamId: string, filters: ApiFilter[] = [], sort = '', page = 1, limit = 10) {
    const url = this.buildUrl(`teams/${teamId}/users`, filters, sort, page, limit);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Add users to a team
   */
  teamAddUsers(teamId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}/users_add`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Remove users from a team
   */
  teamRemoveUsers(teamId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}/users_remove`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Set a role for user in a team
   */
  teamSetRoleForUser(teamId: string, userId: string, role: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}/users_set_role/${userId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      { role },
    );
  }

  /**
   * Get clients from team
   */
  teamClients(teamId: string, filters: ApiFilter[] = [], sort = '', page = 1, limit = 10) {
    const url = this.buildUrl(`teams/${teamId}/clients`, filters, sort, page, limit);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Add clients to team
   */
  teamAddClients(teamId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}/clients_add`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Add clients to team
   */
  teamAddByFilterClients(teamId: string, filters: ApiFilter[] = []) {
    const url = this.buildUrl(`teams/${teamId}/clients_add_bulk`, filters);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_POST,
      {},
    );
  }

  /**
   * Remove clients from a team
   */
  teamRemoveClients(teamId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `teams/${teamId}/clients_remove`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }
}
