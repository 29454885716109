import type { AutocompleteGroup } from '@elseu/sdu-titan';
import { AutocompleteSearch } from '@elseu/sdu-titan';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import React, { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

type Props = {
  defaultQuery?: string;
  onSelectOrSubmit: (query: string) => void;
};

const SearchAutocompleteResults = async (
  query: string,
  signal: AbortSignal | undefined,
): Promise<AutocompleteGroup[]> => {
  const response = await Actions.getAPIService().getSearch().autocomplete(query, signal);
  const data = response.getData();

  return [
    {
      name: 'Resultaten binnen Addify',
      type: 'global',
      results: data.results ?? [],
    },
  ];
};

const SearchAutocomplete: React.FC<Props> = ({ defaultQuery, onSelectOrSubmit }) => {
  const queryClient = useQueryClient();
  const [query, setQuery] = useState<string>(defaultQuery ?? '');
  const [search, setSearch] = useState<string>('');
  useDebounce(
    () => {
      // invalidate
      queryClient.cancelQueries({
        queryKey: ['searchQuery'],
      });
      // set new search value for the query
      setSearch(query);
    },
    250,
    [query],
  );

  const { data: suggestions, isFetching } = useQuery({
    queryKey: ['searchQuery', search],
    queryFn: ({ signal }) => SearchAutocompleteResults(search, signal),
    enabled: search.length > 2,
    staleTime: 5000, // 5 seconds
    gcTime: 5000,
    retry: 0,
  });

  const onSelectOrSubmitCallback = useCallback(
    (query: string) => {
      setQuery(query);
      onSelectOrSubmit(query);
    },
    [onSelectOrSubmit],
  );

  return (
    <AutocompleteSearch
      inputProps={{
        autoComplete: 'off',
        label: 'zoeken',
        name: 'autocomplete',
        isClearable: true,
        placeholder: 'Zoeken naar dossiers, klanten, signaleringen of...',
      }}
      isLoading={isFetching}
      mapSuggestionToMenuItemProps={(suggestion) => ({ item: { label: suggestion.text } })}
      query={query}
      suggestions={suggestions}
      onQueryChange={setQuery}
      onSelect={({ item }) => {
        if (item) {
          onSelectOrSubmitCallback(item.text);
        }
      }}
      onSubmit={onSelectOrSubmitCallback}
    />
  );
};

export default SearchAutocomplete;
