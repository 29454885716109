import ContextItem from 'models/dossier/ContextItem';

/**
 * Tool
 */
export default class Tool extends ContextItem {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...ContextItem.propTypes,
  };

  /**
   * @type {string}
   */
  type = 'tool';
}
