import type { DesignTokensProps, Icon } from '@elseu/sdu-titan';
import {
  BellOutlineIcon,
  Box,
  BoxAltIcon,
  ChatIcon,
  FileIcon,
  FolderIcon,
  MagazineIcon,
  PeopleIcon,
  RadioIcon,
  TasklistIcon,
  UpgradeIcon,
} from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

type Props = {
  icon: string;
  isViewed?: boolean;
};

type IconSettingType = {
  color: keyof DesignTokensProps['colors'];
  iconColor: keyof DesignTokensProps['colors'];
  Icon: Icon;
};

const SizableBox = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const UnreadIndicator = styled.div`
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.designTokens.colors.grey0};
  background-color: ${({ theme }) => theme.designTokens.colors.secondary40};
  width: 6px;
  height: 6px;
  position: absolute;
  right: -1px;
  top: -1px;
`;

const iconSettings: Record<string, IconSettingType> = {
  signalings: {
    color: 'chartCategorical02100',
    iconColor: 'grey0',
    Icon: UpgradeIcon,
  },
  'signaling.added': {
    color: 'chartCategorical02100',
    iconColor: 'grey0',
    Icon: RadioIcon,
  },
  'signaling.assigned': {
    color: 'chartCategorical02100',
    iconColor: 'grey0',
    Icon: RadioIcon,
  },
  'signaling.task.assigned': {
    color: 'chartCategorical02100',
    iconColor: 'grey0',
    Icon: TasklistIcon,
  },
  contactnotes: {
    color: 'primary100',
    iconColor: 'grey0',
    Icon: MagazineIcon,
  },
  'contactnote.added': {
    color: 'chartCategorical03100',
    iconColor: 'grey0',
    Icon: ChatIcon,
  },
  'contactnote.assigned': {
    color: 'chartCategorical03100',
    iconColor: 'grey0',
    Icon: ChatIcon,
  },
  'contactnote.task.assigned': {
    color: 'chartCategorical03100',
    iconColor: 'grey0',
    Icon: TasklistIcon,
  },
  dossiers: {
    color: 'primary50',
    iconColor: 'grey0',
    Icon: FolderIcon,
  },
  'dossier.added': {
    color: 'chartCategorical07100',
    iconColor: 'grey0',
    Icon: BoxAltIcon,
  },
  'dossier.assigned': {
    color: 'chartCategorical07100',
    iconColor: 'grey0',
    Icon: BoxAltIcon,
  },
  'dossier.task.assigned': {
    color: 'chartCategorical07100',
    iconColor: 'grey0',
    Icon: TasklistIcon,
  },
  'dossier.mention': {
    color: 'chartCategorical01100',
    iconColor: 'grey0',
    Icon: ChatIcon,
  },
  'signaling.mention': {
    color: 'chartCategorical01100',
    iconColor: 'grey0',
    Icon: ChatIcon,
  },
  'contactnote.mention': {
    color: 'chartCategorical01100',
    iconColor: 'grey0',
    Icon: ChatIcon,
  },
  'client.mention': {
    color: 'chartCategorical05100',
    iconColor: 'grey0',
    Icon: PeopleIcon,
  },
  'client.task.assigned': {
    color: 'chartCategorical05100',
    iconColor: 'grey0',
    Icon: PeopleIcon,
  },
  'client.assigned': {
    color: 'chartCategorical05100',
    iconColor: 'grey0',
    Icon: PeopleIcon,
  },
  'team.client_add_bulk': {
    color: 'chartCategorical05100',
    iconColor: 'grey0',
    Icon: PeopleIcon,
  },
  attachments: {
    color: 'chartCategorical03100',
    iconColor: 'grey0',
    Icon: FileIcon,
  },
  clientusers: {
    color: 'chartCategorical05100',
    iconColor: 'grey0',
    Icon: PeopleIcon,
  },
  tasks: {
    color: 'chartCategorical06100',
    iconColor: 'grey0',
    Icon: TasklistIcon,
  },
  default: {
    color: 'secondary40',
    iconColor: 'grey0',
    Icon: BellOutlineIcon,
  },
};

const iconSettingsKeys = Object.keys(iconSettings);
const getIconSetting = (icon: string): IconSettingType => {
  if (iconSettingsKeys.includes(icon)) {
    return iconSettings[icon];
  } else {
    return iconSettings.default;
  }
};

export const NotificationTypeIcon: React.FC<Props> = ({ icon, isViewed = false }) => {
  const iconSetting = getIconSetting(icon);
  const Icon = iconSetting.Icon;

  return (
    <SizableBox backgroundColor={iconSetting.color} borderRadius={1}>
      {!isViewed && <UnreadIndicator />}
      <Icon color={iconSetting.iconColor} />
    </SizableBox>
  );
};
