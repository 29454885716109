import React, { Suspense } from 'react';

import LazyLoader from '../loader/LazyLoader';

type Props = {
  children: React.ReactElement;
};

export const Lazy: React.FC<Props> = ({ children }) => (
  <Suspense fallback={<LazyLoader />}>{children}</Suspense>
);
