import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * Actuality
 */
export default class Actuality extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.oneOf([
      'rekentool',
      'tijdlijn',
      'schema',
      'stappenplan',
      'checklist',
      'flowchart',
      'modeldocument',
      'decision',
      'law-article',
      'jurisprudence',
      'article',
      '',
    ]),
    description: PropTypes.string,
  };

  /**
   * @type {string}
   */
  name;

  /**
   * Remote url to more information about this actuality.
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  type;

  /**
   * @type {string}
   */
  description;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   * @returns {string}
   */
  getDescription() {
    return this.description;
  }
}
