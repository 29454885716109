import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * SubOfficeLinkApi API class
 *
 * API Methods to enable, disable and configure the sub office plugin.
 */
export default class SubOfficeLinkApi extends APIEndpoint {
  // region sub office settings

  /**
   * Retrieve the sub office plugin active state and all configured settings.
   *
   * @returns {Promise}
   */
  getSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('sub_office_link/admin');
  }

  /**
   * Save sub office plugin active state and settings.
   *
   * @param {boolean} active
   * @param {boolean} removeEveryoneTeamWhenLinking
   * @param {boolean} removeOtherTeamsWhenLinking
   * @param {boolean} dossierAddTeamsFromClients
   * @param {string} dossierDefaultTeam
   * @returns {Promise}
   */
  saveSettings(
    active,
    removeEveryoneTeamWhenLinking,
    removeOtherTeamsWhenLinking,
    dossierAddTeamsFromClients,
    dossierDefaultTeam,
  ) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sub_office_link/admin',
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        active,
        removeEveryoneTeamWhenLinking,
        removeOtherTeamsWhenLinking,
        dossierAddTeamsFromClients,
        dossierDefaultTeam,
      },
    );
  }

  // endregion

  // region team ad mapping endpoint

  /**
   * Get team AD mapping items
   *
   * @return {JSendRequest}
   */
  getTeamAdMapping() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sub_office_link/admin/team_ad',
    );
  }

  /**
   * Create team AD mapping item
   *
   * @param {object} values
   * @return {JSendRequest}
   */
  createTeamAdMapping(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sub_office_link/admin/team_ad',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update team AD mapping item
   *
   * @param {string} id
   * @param {object} values
   * @return {JSendRequest}
   */
  updateTeamAdMapping(id, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `sub_office_link/admin/team_ad/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete team AD mapping item
   *
   * @param {string} id
   * @return {JSendRequest}
   */
  deleteTeamAdMapping(id) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `sub_office_link/admin/team_ad/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // endregion
}
