/* eslint-disable react/boolean-prop-naming */
import './assets/styles/IconComponent.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export type IconComponentProps = {
  className?: string;
  size?: string | number;
  color?: string;
  colorHover?: string;
  clickable?: boolean;
  inlineBlock?: boolean;
  [key: string]: any;
};

type StylePropType = {
  [key: string]: any;
};

const colorsDefault: string[] = [
  'primary-white',
  'primary-blue',
  'primary-dark-grey',
  'primary-middle-grey',
  'primary-light-grey',
  'primary-red',
  'primary-orange',
  'primary-black',

  'secondary-light-blue',
  'secondary-light-orange',
  'secondary-green',
  'secondary-yellow',
  'secondary-light-yellow',
  'secondary-dark-red',
  'secondary-dark-grey',
  'secondary-middle-grey',
  'secondary-light-grey',

  'post-it-yellow',
  'post-it-green',
  'post-it-blue',
  'post-it-orange',
  'post-it-magenta',

  'graph-light',
  'graph-mid',

  'styleguide-meta',
  'not-selected',
  'dimmer',
  'card-head-diffuse',
  'primary-blue-hover',
  'secondary-dark-red-hover',
];

/**
 * Check if color value is one in the colorsDefault array.
 *
 * @param {string} color
 * @return {string}
 */
const determineColor = (color: string) => {
  if (colorsDefault.includes(color)) {
    return `var(--color-${color})`;
  } else {
    return color;
  }
};

/**
 * Icon component
 *
 * @param {string} className
 * @param {string|number} size
 * @param {string} color
 * @param {string} colorHover
 * @param {boolean} clickable
 * @param {boolean} inlineBlock
 * @param {object} extraProps
 * @param {*} iconSvg
 * @return {*}
 * @constructor
 */
const IconComponent = (
  {
    className,
    size,
    color,
    colorHover,
    clickable,
    inlineBlock,
    coloredIcon = false,
    ...extraProps
  }: IconComponentProps,
  iconSvg: React.ReactElement,
) => {
  // create style for classname
  const style: StylePropType = {};

  if (size) {
    style['--icon-size'] = size;
  }

  if (color) {
    style['--icon-color-normal'] = determineColor(color);
  }
  if (colorHover) {
    style['--icon-color-hover'] = determineColor(colorHover);
  }

  if (Number.isInteger(style['--icon-size'])) {
    // add px if size is a number
    style['--icon-size'] += 'px';
  }

  return React.createElement(
    'span',
    {
      className: classNames(
        'c-icon-component',
        {
          'c-icon-component__single-color': !coloredIcon,
          'c-icon-component__with-hover': colorHover !== undefined,
          'c-icon-component__clickable': clickable,
          'c-icon-component__inline-block': inlineBlock,
        },
        className,
      ),
      style,
      ...extraProps,
    },
    iconSvg,
  );
};

IconComponent.propTypes = {
  classNames: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  colorHover: PropTypes.string,
  clickable: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  coloredIcon: PropTypes.bool,
};

IconComponent.defaultProps = {
  // size: 24,
};

export default IconComponent;
