import './assets/styles/Button.scss';

import classNames from 'classnames';
import React from 'react';

// different appearances of the button
export enum ButtonAppearanceTypes {
  APPEARANCE_DEFAULT = 'default',
  APPEARANCE_SECONDARY = 'secondary',
  APPEARANCE_DARK_RED = 'dark-red',
  APPEARANCE_LINK = 'link',
  APPEARANCE_LINK_SMALL = 'link-small',
  APPEARANCE_ICON_ONLY = 'icon-only',
  APPEARANCE_ROUND = 'round',
}

type Props = React.PropsWithChildren<{
  buttonRef?: any;
  className?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  appearance?: ButtonAppearanceTypes;
  iconPrefix?: any;
  iconSuffix?: any;
  onClick?: () => void;
  onMouseDown?: (event: any) => void;
}>;

/**
 * Button
 *
 * @param {string} buttonRef
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {string} appearance
 * @param {node} iconPrefix
 * @param {node} iconSuffix
 * @param {number} isFullWidth
 * @param {function} onClick
 * @param {node} children
 * @param {node} children
 * @param {node} children
 * @param {object} props
 * @return {*}
 * @constructor
 */
const Button: React.FC<Props> = ({
  buttonRef,
  className,
  isDisabled = false,
  appearance = ButtonAppearanceTypes.APPEARANCE_DEFAULT,
  iconPrefix,
  iconSuffix,
  isFullWidth = false,
  onClick,
  children,
  ...props
}) => (
  <button
    ref={buttonRef}
    className={classNames('button', className, `button--${appearance}`, {
      'button--disabled': isDisabled,
      'button--full-width': isFullWidth,
    })}
    disabled={isDisabled}
    type="button"
    onClick={() => {
      if (!isDisabled && onClick) {
        onClick();
      }
    }}
    {...props}
  >
    <span className="button__label">
      {iconPrefix && <span className="button__label__prefix">{iconPrefix}</span>}
      <span className="button__label__content">{children}</span>
      {iconSuffix && <span className="button__label__suffix">{iconSuffix}</span>}
    </span>
  </button>
);

export default Button;
