import ContextItem from 'models/dossier/ContextItem';

/**
 * PartialConsideration
 */
export default class PartialConsideration extends ContextItem {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...ContextItem.propTypes,
  };

  /**
   * @type {string}
   */
  type = 'partial_consideration';
}
