/**
 * LocalStorageService wrapper
 */
class LocalStorageService {
  /**
   * Local object to store values key value pairs in.
   * @var {object}
   * @private
   */
  storage = {};

  /**
   * Retrieve an indicator if the local storage is supported.
   * @private
   * @returns {boolean}
   */
  static isLocalStorageSupported() {
    try {
      window.localStorage.setItem('test', '1');
      window.localStorage.removeItem('test');
    } catch (error) {
      return false;
    }
    return true;
  }

  /**
   * Remove a single item from the local store.
   * @param {string} name
   */
  removeItem(name) {
    this.setItem(name, null);
  }

  /**
   * Store the value under the name key.
   * @param {string} name
   * @param {*} value
   */
  setItem(name, value) {
    if (LocalStorageService.isLocalStorageSupported()) {
      if (value === null) {
        window.localStorage.removeItem(name);
      } else {
        window.localStorage.setItem(name, value);
      }
    } else {
      if (value === null) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.storage[name];
      } else {
        this.storage[name] = value;
      }
    }
  }

  /**
   * Retrieve the value that was stored underneath the name.
   * @param {string} name
   * @param {*} defaultValue
   * @returns {*}
   */
  getItem(name, defaultValue = null) {
    let value;
    if (LocalStorageService.isLocalStorageSupported()) {
      value = window.localStorage.getItem(name);
    } else {
      value = this.storage[name];
    }

    if (value === undefined || value === 'undefined' || value === null) {
      value = defaultValue;
    }

    return value;
  }

  /**
   * Return all local storage items saved.
   * @return {object}
   */
  getItems() {
    if (LocalStorageService.isLocalStorageSupported()) {
      return { ...window.localStorage };
    }
    return this.storage;
  }
}

export default new LocalStorageService();
