import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * ModelType
 */
export default class ModelType extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
  };

  /**
   * ModelType name.
   * @type {string}
   */
  name;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }
}
