import { convertToDate, normalizeMap } from 'helpers/normalizehelper';

import type {
  NotificationItem,
  NotificationType,
  NotificationUnreadResponse,
  NotificationUserType,
} from './types';

export const normalizeNotificationItem = (data: any): NotificationItem => ({
  id: data.id,
  type: data.type,
  title: data.title,
  body: data.body,
  url: data.url,
  createdAt: convertToDate(data.createdAt) ?? new Date(),
  isViewed: data.isViewed,
});

export const normalizeNotificationType = (data: any): NotificationType => ({
  type: data.type,
  group: data.group,
  name: data.name,
  description: data.description,
  user: normalizeNotificationUserType(data.user),
});

export const normalizeNotificationUserType = (data: any): NotificationUserType => ({
  isActive: data.isActive,
  shouldNotifyViaNotificationCenter: data.shouldNotifyViaNotificationCenter,
  shouldNotifyViaEmail: data.shouldNotifyViaEmail,
  mailFrequency: data.mailFrequency,
});

export const normalizeNotificationUnreadResponse = (data: any): NotificationUnreadResponse => ({
  unread: data.unread ?? 0,
  unreadNotifications: normalizeMap<NotificationItem>(
    data.unreadNotifications,
    normalizeNotificationItem,
  ),
});
