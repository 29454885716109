import JSendResponse from './JSendResponse';

/**
 * JSendRequest wrapper
 * @see https://labs.omniti.com/labs/jsend.
 */
export default class JSendRequest {
  /**
   * Request methods that are supported for the request class.
   * @type {String}
   */
  static REQUEST_METHOD_GET = 'GET';
  static REQUEST_METHOD_PUT = 'PUT';
  static REQUEST_METHOD_POST = 'POST';
  static REQUEST_METHOD_PATCH = 'PATCH';
  static REQUEST_METHOD_DELETE = 'DELETE';

  /**
   * Base url to use in all connection requests.
   * @type {String}
   */
  baseUrl = '';

  /**
   * Authorization token for the api.
   * @type {null|String} token
   */
  token = null;

  /**
   * @type {AbortSignal|undefined}
   */
  signal = undefined;

  /**
   * Class constructor
   *
   * @param {String} baseUrl
   * @param {null|String} token
   * @param {AbortSignal|undefined} token
   */
  constructor(baseUrl, token = null, signal = undefined) {
    this.baseUrl = baseUrl;
    this.token = token;
    this.signal = signal;
  }

  /**
   * Convert an object into FormData.
   * @param {FormData} formData
   * @param {object|null} data
   * @param {null|string} parentKey
   */
  buildFormData = (formData, data, parentKey = null) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  };

  /**
   * Upload the given file and attach any data given optionally to the form data.
   * @param {string} url
   * @param {File} file
   * @param {null|object} data
   * @param {string} uploadName
   * @return {Promise<any>}
   */
  upload(url, file, data = {}, uploadName = 'file[file]') {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append(uploadName, file);

    this.buildFormData(formData, data);

    const headers = {
      Authorization: `Token ${this.getToken()}`,
    };
    const config = {
      method: JSendRequest.REQUEST_METHOD_POST,
      headers: new Headers(headers),
      body: formData,
      signal: this.signal,
    };

    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + url, config)
        .then((response) => {
          const json = response.json();

          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (json) {
            return json;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then((json) => {
          const jSendResponse = JSendResponse.fromObject(json);
          if (jSendResponse.isSuccess()) {
            resolve(jSendResponse);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (jSendResponse) {
            reject(jSendResponse);
          }
        })
        .catch((error) => {
          if (error.statusText && error.status) {
            reject(JSendResponse.fromError({ message: error.statusText, code: error.status }));
          } else {
            reject(JSendResponse.fromError(error));
          }
        });
    });
  }

  /**
   * Build a request to the given url and return the result or failure in a promise, optionally the request method
   * sent headers and data can be altered. By default the request is a GET request.
   *
   * As a convenience the request method types can be found in the class as statics to have auto completion.
   *
   *  var api = new Api();
   *  api.request('url', Api.REQUEST_METHOD_POST)
   *      .then((jSendResponse) => {})
   *      .catch((jSendResponse) => {});
   *
   * Attach post data or change headers like:
   *
   *  var api = new Api();
   *  api.request('url', Api.REQUEST_METHOD_POST, {'dataField':'dataValue'}, {'Authorization': 'Token 123456'})
   *      .then((jSendResponse) => {})
   *      .catch((jSendResponse) => {});
   *
   * @throws InvalidRequestMethod Thrown if the given request method is not supported.
   *
   * @param {String} url
   * @param {String|null} method
   * @param {null|object} data
   * @param {null|object} headers
   *
   * @return Promise<JSendResponse>
   */
  request(url, method = JSendRequest.REQUEST_METHOD_GET, data = null, headers = null) {
    // sanity check to see if we support the given method, if not throw an error.
    if (!JSendRequest.supportsRequestMethod(method)) {
      throw new Error(`JSendRequest does not support the given method ${method}.`);
    }

    // setup config add the method as default value.
    const config = {
      method,
      signal: this.signal,
    };

    // if post data is given add the post data to the settings.
    if (data !== null) {
      config.body = JSON.stringify(data);
    }

    config.headers = new Headers(headers || {});
    config.headers.append('Content-Type', 'application/json');
    config.credentials = 'include';

    // check if we have a token, if so inject it within the headers
    if (this.hasToken()) {
      config.headers.append('Authorization', `Token ${this.getToken()}`);
    }

    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + url, config)
        .then((response) => {
          const json = response.json();

          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (json) {
            return json;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then((json) => {
          const jSendResponse = JSendResponse.fromObject(json);
          if (jSendResponse.isSuccess()) {
            resolve(jSendResponse);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (jSendResponse) {
            reject(jSendResponse);
          }
        })
        .catch((error) => {
          if (error.statusText && error.status) {
            reject(JSendResponse.fromError({ message: error.statusText, code: error.status }));
          } else {
            reject(JSendResponse.fromError(error));
          }
        });
    });
  }

  /**
   * All request methods that the api client supports.
   *
   * @return {Array}
   */
  static getSupportedRequestMethods() {
    return [
      JSendRequest.REQUEST_METHOD_GET,
      JSendRequest.REQUEST_METHOD_PUT,
      JSendRequest.REQUEST_METHOD_POST,
      JSendRequest.REQUEST_METHOD_PATCH,
      JSendRequest.REQUEST_METHOD_DELETE,
    ];
  }

  /**
   * Whether the api client supports the given request method or not.
   *
   * @param {String} method
   * @return {boolean}
   */
  static supportsRequestMethod(method) {
    return JSendRequest.getSupportedRequestMethods().includes(method);
  }

  /**
   * Retrieve an indicator if we have a token stored.
   * @return {boolean}
   */
  hasToken() {
    return this.token !== null;
  }

  /**
   * Retrieve the current token that is stored.
   * @return {String}
   */
  getToken() {
    return this.token;
  }

  /**
   * Clear the current stored token.
   * @return {JSendRequest}
   */
  clearToken() {
    this.token = null;
    return this;
  }

  /**
   * Assign a token that is used within all requests.
   * @param {String} token
   * @return {JSendRequest}
   */
  setToken(token) {
    this.token = token;
    return this;
  }
}
