import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Security Login API
 */
export default class SecurityLoginApi extends APIEndpoint {
  /**
   * Get settings
   *
   * @returns {Promise}
   */
  getSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'security_login/admin',
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Save settings
   *
   * @param {object} values
   * @returns {Promise}
   */
  setSettings(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'security_login/admin/settings',
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Get users
   *
   * @returns {Promise}
   */
  getUsers() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'security_login/admin/users',
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Activate user
   *
   * @param {srting} userId
   * @returns {Promise}
   */
  activateUser(userId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `security_login/admin/users/${userId}/activate`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  /**
   * Deactivate user
   *
   * @param {string} userId
   * @returns {Promise}
   */
  deactivateUser(userId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `security_login/admin/users/${userId}/deactivate`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }
}
