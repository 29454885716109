import classNames from 'classnames';
import React from 'react';

import TopLoader from '../loader/TopLoader';

type Props = {
  className?: string;
  isHidden?: boolean;
};
/**
 * Page loader component
 *
 * @param {string} className
 * @param {boolean} hidden
 * @returns {JSX.Element}
 * @constructor
 */
const PageLoader: React.FC<Props> = ({ className, isHidden }) => (
  <TopLoader className={classNames(className, 'c-page-loader')} isHidden={isHidden || false} />
);

export default PageLoader;
