import JSendRequest from 'services/JSendRequest';

import APIEndpoint from './APIEndpoint';

/**
 * Branding API
 */
export default class BrandingApi extends APIEndpoint {
  /**
   * Get branding settings
   *
   * @return {JSendRequest}
   */
  getSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('branding/admin');
  }

  /**
   * Save branding settings
   *
   * @param {Object} values
   * @return {JSendRequest}
   */
  updateSettings(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'branding/admin',
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Upload branding image
   *
   * @param {File} image
   * @return {JSendRequest}
   */
  uploadBrandingImage(image) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      'branding/admin/branding_image',
      image,
      {},
    );
  }

  /**
   * Reset branding image
   *
   * @return {JSendRequest}
   */
  resetBrandingImage() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'branding/admin/branding_image',
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
