import type { SidebarItemProps } from '@elseu/sdu-titan';
import {
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerList,
  DrawerListItem,
  Text,
} from '@elseu/sdu-titan';
import type Profile from 'models/me/Profile';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  profile: Profile;
  isShown: boolean;
  menuItems?: SidebarItemProps[];
  versionNumber: string;
  onClose: () => void;
};

const StyledVersion = styled(Text)`
  bottom: 1.5rem;
  left: 2.5rem;
`;

const DrawerProfile: React.FC<Props> = ({
  profile,
  menuItems,
  isShown,
  versionNumber,
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <Drawer isShown={isShown} position="right" width={480} onClose={onClose}>
      <DrawerContainer
        hasCloseButton
        avatar={profile.getAvatarUrl()}
        footer={
          <StyledVersion isBlock color="grey70" type="labelSmall">
            V: {versionNumber}
          </StyledVersion>
        }
        header={profile.getFullName()}
        headerMeta={
          <Text color="grey70" type="labelSmall">
            {profile.getEmail()}
          </Text>
        }
        headerVariant="avatar"
      >
        <DrawerContent>
          {menuItems && (
            <DrawerList spaceAfter={20} variant="compact">
              {menuItems.map((item) => {
                return (
                  <DrawerListItem
                    key={item.id}
                    prefixAdornment={item.icon ? React.createElement(item.icon) : undefined}
                    onClick={() => {
                      if (/^(?:[a-z]+:)?\/\//.exec(item.to)) {
                        window.open(item.to);
                      } else {
                        navigate(item.to);
                      }
                      onClose();
                    }}
                  >
                    {item.title}
                  </DrawerListItem>
                );
              })}
            </DrawerList>
          )}
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export default DrawerProfile;
