import DataModel, { PropTypes } from 'models/base/DataModel';

import Consideration from './Consideration';

/**
 * Topic
 */
export default class Topic extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    considerations: PropTypes.arrayOf(PropTypes.shape(Consideration.propTypes)),
    isDeleted: PropTypes.bool,
    isManual: PropTypes.bool,
    createdDateTime: PropTypes.string,
    metaInfo: PropTypes.shape({
      countDossiers: PropTypes.number,
    }),
    createdBy: PropTypes.string,
  };

  /**
   * Topic name.
   * @type {string}
   */
  name;

  /**
   * @type {Consideration[]|undefined}
   */
  considerations;

  /**
   * @type {boolean}
   */
  isDeleted;

  /**
   * @type {boolean}
   */
  isManual;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {object|undefined}
   */
  metaInfo;

  /**
   * @var {string}
   */
  createdBy;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {boolean}
   */
  getIsDeleted() {
    return this.isDeleted;
  }

  /**
   * @returns {boolean}
   */
  getIsManual() {
    return this.isManual;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {number}
   */
  getCountDossiers() {
    if (this.metaInfo?.countDossiers) {
      return this.metaInfo.countDossiers;
    }
    return 0;
  }

  /**
   * @returns {Consideration[]}
   */
  getConsideration() {
    if (this.considerations === undefined) {
      this.considerations = [];
    }

    return this.considerations;
  }

  /**
   * @returns {string}
   */
  getCreatedBy() {
    return this.createdBy;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'considerations') {
      value = Consideration.createInstancesByArray(value);
    }

    return super.set(fieldName, value);
  }
}
