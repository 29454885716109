import React from 'react';
import type { Toast } from 'react-hot-toast/headless';
import { toast } from 'react-hot-toast/headless';
import { useNavigate } from 'react-router';

import { useNotificationMarkAsRead } from './hooks/useNotificationMarkAsRead';
import type { NotificationListItemProps } from './NotificationListItem';
import { NotificationListItem } from './NotificationListItem';

type Props = Omit<NotificationListItemProps, 'type' | 'isClickable' | 'onClick' | 'onDismiss'> & {
  toastItem: Toast;
};

export const ToastNotification: React.FC<Props> = ({ item, toastItem }) => {
  const navigate = useNavigate();
  const markNotificationAsRead = useNotificationMarkAsRead();

  return (
    <NotificationListItem
      isClickable={!!item.url}
      item={item}
      type="toast"
      onClick={() => {
        if (item.url) {
          markNotificationAsRead(item);
          navigate(item.url);
          toast.dismiss(toastItem.id);
        }
      }}
      onDismiss={() => {
        markNotificationAsRead(item);
        toast.dismiss(toastItem.id);
      }}
    />
  );
};
