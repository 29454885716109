import {
  Box,
  Button,
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerLoader,
  EmptyState,
  Flex,
  Spinner,
  t,
  Text,
} from '@elseu/sdu-titan';
import { format, intlFormatDistance } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { groupBy } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useIntersection } from 'react-use';
import styled from 'styled-components';

import { useNotificationMarkAsRead } from './hooks/useNotificationMarkAsRead';
import { useNotifications } from './hooks/useNotifications';
import { NotificationListItem } from './NotificationListItem';

enum NotificationDrawerViews {
  LOADING = 0,
  ITEMS = 1,
  CLEARED = 2,
}

type Props = {
  isShown: boolean;
  onClose: () => void;
};

const GroupText = styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const DrawerContentRelative = styled(DrawerContent)`
  position: relative;
`;

const DrawerContentLoader = styled(DrawerContent)`
  height: calc(100dvh - 256px);
`;
const EmptyStateCenter = styled(Flex)`
  height: calc(100dvh - 256px);
`;

const ClearAllContainer = styled(Box)`
  position: absolute;
  right: 24px;
  top: 34px;
`;

const DateGroupVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const ItemVariants = {
  visible: {
    opacity: 1,
    translateY: 0,
    scale: 1,
    transition: {
      duration: 0.15,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.5,
    translateY: -50,
    transition: {
      duration: 0.15,
    },
  },
};

export const NotificationsOverviewDrawer: React.FC<Props> = ({ isShown, onClose }) => {
  const {
    notifications,
    isLoading,
    isFetchingNextPage,
    hasMoreResults,
    fetchNextPage,
    dismissNotification,
    markAllAsRead,
  } = useNotifications({ enabled: isShown });
  const markNotificationAsRead = useNotificationMarkAsRead();

  const navigate = useNavigate();
  const intersectionRef = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !isFetchingNextPage && hasMoreResults) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasMoreResults, intersection?.isIntersecting, isFetchingNextPage]);

  const groupedNotifications = useMemo(() => {
    const results = groupBy(notifications, (item) => {
      return format(item.createdAt, 'yyyy-MM-dd');
    });
    return results;
  }, [notifications]);
  const groupNotifcationKeys = useMemo(
    () => Object.keys(groupedNotifications),
    [groupedNotifications],
  );

  const renderItem = useMemo(() => {
    if (isLoading) {
      return NotificationDrawerViews.LOADING;
    }

    if (notifications.length === 0) {
      return NotificationDrawerViews.CLEARED;
    }

    return NotificationDrawerViews.ITEMS;
  }, [isLoading, notifications.length]);

  return (
    <Drawer isShown={isShown} position="right" width={480} onClose={onClose}>
      <DrawerContainer
        footer={
          <Button
            size="S"
            type="button"
            variant="clear"
            onClick={() => {
              navigate('/settings/notifications');
              onClose();
            }}
          >
            {t('Instellingen')}
          </Button>
        }
        header={t('Notificaties')}
      >
        {renderItem === NotificationDrawerViews.LOADING && (
          <DrawerContentLoader>
            <DrawerLoader />
          </DrawerContentLoader>
        )}
        {renderItem === NotificationDrawerViews.ITEMS && notifications.length > 0 && (
          <DrawerContentRelative>
            <AnimatePresence>
              {groupNotifcationKeys.map((groupKey) => {
                const items = groupedNotifications[groupKey];
                const humanReadable = intlFormatDistance(new Date(groupKey), new Date(), {
                  unit: 'day',
                  locale: 'nl',
                });

                return (
                  <motion.div
                    key={`notificationGroup${groupKey}`}
                    animate="visible"
                    initial="hidden"
                    variants={DateGroupVariants}
                  >
                    <Box py={4}>
                      <GroupText color="grey70" type="label">
                        {humanReadable}
                      </GroupText>
                    </Box>
                    <motion.div
                      animate="visible"
                      exit="hidden"
                      initial="hidden"
                      transition={{
                        staggerChildren: 0.5,
                      }}
                      variants={DateGroupVariants}
                    >
                      <AnimatePresence>
                        {items.map((item) => (
                          <motion.div
                            key={item.id}
                            animate="visible"
                            exit="hidden"
                            initial="hidden"
                            variants={ItemVariants}
                          >
                            <NotificationListItem
                              isClickable={!!item.url}
                              item={item}
                              type="list"
                              onClick={() => {
                                if (!item.isViewed) {
                                  markNotificationAsRead(item);
                                }

                                if (item.url) {
                                  navigate(item.url);
                                  onClose();
                                }
                              }}
                              onDismiss={() => {
                                dismissNotification(item);
                              }}
                            />
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </motion.div>
                  </motion.div>
                );
              })}
            </AnimatePresence>
            <div ref={intersectionRef}>
              {hasMoreResults && !isFetchingNextPage && (
                <Box py={4}>
                  <Flex alignItems="center" flexDirection="column">
                    <Button size="S" onClick={() => fetchNextPage()}>
                      {t('Laad meer')}
                    </Button>
                  </Flex>
                </Box>
              )}
              {isFetchingNextPage && (
                <Box py={4}>
                  <Spinner size={16} />
                </Box>
              )}
            </div>

            <ClearAllContainer>
              <Button size="S" type="button" variant="clear" onClick={() => markAllAsRead()}>
                {t('Markeer alles als gelezen')}
              </Button>
            </ClearAllContainer>
          </DrawerContentRelative>
        )}
        {renderItem === NotificationDrawerViews.CLEARED && (
          <EmptyStateCenter
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            py={8}
          >
            <EmptyState
              isCentered
              description={t('Je bent weer helemaal bij.')}
              illustration="success"
              title={t('Geen notificaties')}
            />
          </EmptyStateCenter>
        )}
      </DrawerContainer>
    </Drawer>
  );
};
