import ContextItem from 'models/dossier/ContextItem';

/**
 * Source
 */
export default class Source extends ContextItem {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...ContextItem.propTypes,
  };

  /**
   * @type {string}
   */
  type = 'source';
}
