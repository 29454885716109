import { useMutation, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { useCallback } from 'react';

import type { NotificationItem } from '../types';

export const useNotificationMarkAsRead = () => {
  const queryClient = useQueryClient();
  const markNotificationAsReadMutate = useMutation<any, unknown, NotificationItem>({
    mutationFn: (notification) => Actions.getAPIService().notifications().setRead(notification.id),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications-count'],
      });
    },
  });

  const markNotificationAsRead = useCallback(
    (notification: NotificationItem) => {
      return markNotificationAsReadMutate.mutateAsync(notification);
    },
    [markNotificationAsReadMutate],
  );

  return markNotificationAsRead;
};
