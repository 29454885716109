import type { TitanRouterLinkResolver, TitanRouterLinkWrapper } from '@elseu/sdu-titan';
import { useScrollToAnchorOnClick } from '@elseu/sdu-titan';
import type { MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import { useCallback } from 'react';
import * as React from 'react';
import { useHref, useMatch, useNavigate } from 'react-router-dom';

/**
 * This wrapper enhances its child link element so that it programmatically
 * navigates to the specified route.
 */
export const ReactRouterLink: React.FC<{ children: ReactElement }> = ({ children: link }) => {
  const navigate = useNavigate();
  const {
    props: { className: classes, href, onClick: handleClick },
  } = link;
  const match = useMatch(href);
  const className = match ? `${classes || ''} active` : classes;
  const onClick = (e: ReactMouseEvent<HTMLElement>) => {
    // Trigger the original onClick callback if defined.
    handleClick?.(e);

    // With ctrl + click on windows or cmd + click on mac return
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    if (href) {
      navigate(href);
    }
  };

  // to resolve relative paths
  const resolvedHref = useHref(href);

  return React.cloneElement(link, { className, onClick, href: resolvedHref });
};

/**
 * This hook returns a HoC that enhances the link element it receives to work with
 * React Router, and for anchors to scroll to their position on the page.
 */
export const useReactRouterLinkWrapper = () => {
  const navigate = useNavigate();
  const scrollToAnchorOnClick = useScrollToAnchorOnClick(true);

  return useCallback<TitanRouterLinkWrapper>(
    (link: ReactElement) => {
      const {
        props: { href, onClick },
        key,
      } = link;

      // If this is an external link, return it as is without further enhancements.
      if (/^(?:[a-z]+:)?\/\//.exec(href)) {
        return link;
      }

      // If this is a same-page anchor, return the original element that will update
      // the url and scroll to the same-page element on click.
      if (href.startsWith('#')) {
        return React.cloneElement(link, {
          onClick: (e: ReactMouseEvent<HTMLElement>) => {
            onClick?.(e);
            if (e.ctrlKey || e.metaKey) return;
            navigate(href);
            scrollToAnchorOnClick(e);
          },
        });
      }

      // Make the link navigate programmatically to the specified route.
      return <ReactRouterLink key={key ?? undefined}>{link}</ReactRouterLink>;
    },
    [scrollToAnchorOnClick, navigate],
  );
};

export const useReactRouterLinkResolver = () => {
  const navigate = useNavigate();
  const scrollToAnchorOnClick = useScrollToAnchorOnClick(true);

  return useCallback<TitanRouterLinkResolver>(
    (link: HTMLAnchorElement) => {
      if (typeof window === 'undefined') return;
      const href = link.getAttribute('href');
      const isExternal = href?.startsWith('http');

      if (!href || href === '' || isExternal) {
        return;
      }

      const onClickLink = (e: MouseEvent) => {
        // With ctrl + click on windows or cmd + click on mac return
        if (e.ctrlKey || e.metaKey) return;

        e.preventDefault();
        navigate(href);

        // When it is an anchor scroll to anchor.
        if (href.startsWith('#')) {
          scrollToAnchorOnClick(e as any);
        }
      };

      link.addEventListener('click', onClickLink);

      return () => {
        link.removeEventListener('click', onClickLink);
      };
    },
    [navigate, scrollToAnchorOnClick],
  );
};
