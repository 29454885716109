import './assets/styles/HighlightBox.scss';

import classNames from 'classnames';
import CloseIcon from 'components/icons/CloseIcon';
import type { ReactNode } from 'react';
import React from 'react';

export enum IHighlightBoxAppearance {
  APPEARANCE_DEFAULT = 'default',
  APPEARANCE_BLUE = 'blue',
  APPEARANCE_GREEN = 'green',
  APPEARANCE_YELLOW = 'yellow',
  APPEARANCE_RED = 'red',
  APPEARANCE_GREY = 'grey',
}

type IHighlightBox = {
  className?: string;
  appearance: IHighlightBoxAppearance;
  hasShadow?: boolean;
  isClosable?: boolean;
  hasBorder?: boolean;
  onClose?: () => void;
  children?: ReactNode;
};

const HighlightBox: React.FC<IHighlightBox> = ({
  className,
  appearance = IHighlightBoxAppearance.APPEARANCE_DEFAULT,
  hasShadow = false,
  isClosable = false,
  hasBorder = false,
  onClose,
  children,
}) => (
  <div
    className={classNames(
      'c-highlight-box',
      `c-highlight-box__appearance-${appearance}`,
      className,
      {
        'c-highlight-box__shadow': hasShadow,
        'c-highlight-box__closable': isClosable,
        'c-highlight-box__border': hasBorder,
      },
    )}
  >
    {isClosable && onClose && (
      <button className="c-highlight-box__close-button" type="button" onClick={onClose}>
        <CloseIcon size={16} />
      </button>
    )}
    <div className="c-highlight-box__content">{children}</div>
  </div>
);

export default HighlightBox;
