import { BellNotificationIcon, BellOutlineIcon, NavigationWidgetButton } from '@elseu/sdu-titan';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast/headless';
import { useNavigate } from 'react-router-dom';

import { useNotificationUnreadCount } from './hooks/useNotificationUnreadCount';
import { NotificationsOverviewDrawer } from './NotificationsOverviewDrawer';
import { ToastNotification } from './ToastNotification';

export const NotificationNavigationIcon: React.FC = () => {
  const { count, unreadNotifications } = useNotificationUnreadCount();
  const [isDrawerShown, setIsDrawerShown] = useState<boolean>(false);
  const [handledIds, setHandledIds] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (unreadNotifications.length === 0) {
      return;
    }
    const newHandled = handledIds;
    unreadNotifications.forEach((item) => {
      if (!newHandled.includes(item.id)) {
        toast((t) => <ToastNotification item={item} toastItem={t} />, {
          duration: 5000,
          id: item.id,
        });
        newHandled.push(item.id);
      }
    });
    setHandledIds(newHandled);
  }, [handledIds, navigate, unreadNotifications]);

  return (
    <>
      <NavigationWidgetButton type="button" onClick={() => setIsDrawerShown(true)}>
        {count > 0 ? <BellNotificationIcon /> : <BellOutlineIcon />}
      </NavigationWidgetButton>
      <NotificationsOverviewDrawer
        isShown={isDrawerShown}
        onClose={() => {
          setIsDrawerShown(false);
        }}
      />
    </>
  );
};
