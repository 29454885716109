import DataModel, { PropTypes as ModelPropTypes } from 'models/base/DataModel';

/**
 * Profile
 *
 * Model that will hold profile data like full name, email and avatar.
 */
export default class Profile extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    fullName: ModelPropTypes.string,
    email: ModelPropTypes.string,
    avatarUrl: ModelPropTypes.string,
    isOneDriveUserActive: ModelPropTypes.bool,
    roles: ModelPropTypes.array,
    active: ModelPropTypes.bool,
  };

  /**
   * Full name of the user.
   * @type {string}
   */
  fullName;

  /**
   * Email address
   * @type {string}
   */
  email;

  /**
   * URL where we can download the avatar of this user.
   * @type {string}
   */
  avatarUrl;

  /**
   * Notifier to show that onedrive is configured for this user.
   *
   * @type {boolean}
   */
  isOneDriveUserActive;

  /**
   * Roles
   * @type {[string]|undefined}
   */
  roles = ['ROLE_USER'];

  /**
   * @type {boolean}
   */
  active = true;

  /**
   * @returns {string}
   */
  getFullName() {
    return this.fullName;
  }

  /**
   * @returns {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   * @returns {string}
   */
  getAvatarUrl() {
    return this.avatarUrl;
  }

  /**
   * @returns {string[]}
   */
  getRoles() {
    if (this.roles === undefined) {
      this.roles = ['ROLE_USER'];
    }
    return this.roles;
  }

  isActive() {
    return this.active;
  }

  /**
   * Check if the user is a admin
   *
   * @returns {boolean}
   */
  isAdmin() {
    return this.getRoles().includes('ROLE_APP_ADMIN') || this.getRoles().includes('ROLE_ADMIN');
  }

  /**
   * Check if the user is a admin
   *
   * @returns {boolean}
   */
  getIsOneDriveUserActive() {
    return this.oneDriveUserActive;
  }

  /**
   * Create selection option object for UserSelectField component
   *
   * @returns {{image: string, label: string, type: string, value: (string|null), email: string}}
   */
  getSelectOptionObject() {
    return {
      value: this.getIdentity(),
      label: this.getFullName(),
      image: this.getAvatarUrl(),
      email: this.getEmail(),
      type: 'person',
      user: this,
    };
  }
}
