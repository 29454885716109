/**
 * Possible states
 * @type {{SUCCESS: string, BUSY: string, ERROR: string, UNKNOWN: string}}
 */
export const FETCH_STATES = {
  UNKNOWN: 'UNKNOWN', // running a fetch call
  SUCCESS: 'SUCCESS', // fetch state is done
  BUSY: 'BUSY', // running a fetch call
  ERROR: 'ERROR', // fetch state has error
};

const KEY_CONTEXT_LIST = '_contextList';
export const DEFAULT_CONTEXT_LIST_ID = '_default';

// #region state object create
/**
 * Creates a initial state for fetch state.
 *
 * @returns {
 * {
 * currentState: string,
 * isBusy: boolean,
 * isSuccess: boolean,
 * context: any|undefined,
 * hasError: boolean,
 * errorCode: string|undefined,
 * errorContext: any|undefined,
 * errorData: any|undefined,
 * errorMessage: any|undefined,
 * response: any|undefined
 * }
 * }
 */
const init = () => ({
  [KEY_CONTEXT_LIST]: {},
  ...createContextObject(),
});

/**
 * Create fetch state object for a context object
 *
 * @returns {
 * {
 * currentState: string,
 * isBusy: boolean,
 * isSuccess: boolean,
 * context: any|undefined,
 * hasError: boolean,
 * errorCode: string|undefined,
 * errorContext: any|undefined,
 * errorData: any|undefined,
 * errorMessage: any|undefined,
 * response: any|undefined
 * }
 * }
 */
const createContextObject = () => ({
  currentState: FETCH_STATES.UNKNOWN,
  isBusy: false,
  isSuccess: false,
  context: undefined,
  hasError: false,
  errorCode: undefined,
  errorContext: undefined,
  errorData: undefined,
  errorMessage: undefined,
  response: undefined,
});

/**
 * Make a new fetch state object with given overrides.
 *
 * @param {object} overrides
 * @returns {object}
 */
const createStateObject = (overrides) => {
  const initState = createContextObject();
  return {
    ...initState,
    ...overrides,
  };
};
// #endregion

// #region dispatch
/**
 * Set fetch state to busy dispatch
 *
 * @param {string} stateKey
 * @param {string} contextListId
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToBusy =
  (stateKey, contextListId) =>
  ({ setState, getState, dispatch }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    contextList[contextListId] = createStateObject({
      currentState: FETCH_STATES.BUSY,
      isBusy: true,
      context: contextList[contextListId].context,
    });

    setState({
      [stateKey]: {
        ...currentState,
        [KEY_CONTEXT_LIST]: contextList,
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Set fetch state to success dispatch
 *
 * @param {string} stateKey
 * @param {object} response
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToSuccess =
  (stateKey, contextListId, response) =>
  ({ getState, setState, dispatch }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    contextList[contextListId] = createStateObject({
      currentState: FETCH_STATES.SUCCESS,
      isSuccess: true,
      response,
      context:
        Object.prototype.hasOwnProperty.call(contextList, contextListId) &&
        contextList[contextListId].context,
    });

    setState({
      [stateKey]: {
        ...currentState,
        [KEY_CONTEXT_LIST]: contextList,
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Set fetch state to error state dispatch
 *
 * @param {string} stateKey
 * @param {object} response
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToError =
  (stateKey, contextListId, response) =>
  ({ getState, setState, dispatch }) => {
    const defaultMessage = 'Onbekende fout opgetreden';
    const message = response.getMessage ? response.getMessage() : response;
    const data = response.getData ? response.getData() : {};

    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    contextList[contextListId] = createStateObject({
      currentState: FETCH_STATES.ERROR,
      hasError: true,
      errorMessage: message || defaultMessage,
      errorContext: data,
      errorData: data,
      context:
        Object.prototype.hasOwnProperty.call(contextList, contextListId) &&
        contextList[contextListId].context,
    });

    setState({
      [stateKey]: {
        ...currentState,
        ...contextList[contextListId],
        [KEY_CONTEXT_LIST]: contextList,
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Set error message of fetch state dispatch
 *
 * @param {string} stateKey
 * @param {string} message
 * @returns {function(...[*]=)}
 */
const dispatchSetErrorMessageState =
  (stateKey, contextListId, message) =>
  ({ getState, setState, dispatch }) => {
    const currentState = getState()[stateKey];
    setState({
      [stateKey]: {
        ...currentState,
        errorMessage: message,
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Set a context for the fetch state.
 *
 * @param {string} stateKey
 * @param {*} context
 * @returns {function(...[*]=)}
 */
const dispatchSetContextState =
  (stateKey, contextListId, context) =>
  ({ getState, setState, dispatch }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    contextList[contextListId].context = context;

    setState({
      [stateKey]: {
        ...currentState,
        [KEY_CONTEXT_LIST]: contextList,
        context,
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Update object context of fetch state.
 * - remove context that are completed.
 *
 * @param stateKey
 * @returns {function(...[*]=)}
 */
const dispatchSetGlobalFetchUpdate =
  (stateKey) =>
  ({ getState, setState }) => {
    const currentState = getState()[stateKey];
    const contextList = currentState[KEY_CONTEXT_LIST];
    const listKeys = Object.keys(contextList || {});
    const isBusy = listKeys.filter((key) => contextList[key].isBusy).length > 0;
    const isSuccess = listKeys.filter((key) => contextList[key].isSuccess).length > 0;

    setState({
      [stateKey]: {
        ...currentState,
        isBusy,
        isSuccess,
      },
    });
  };

/**
 * Cleanup context list
 *
 * @param stateKey
 * @returns {function(...[*]=)}
 */
const dispatchSetCleanupContextList =
  (stateKey) =>
  ({ getState, setState }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    const contextListKeys = Object.keys(contextList);

    // do cleanup of _currentList key
    contextListKeys.forEach((contextIdKey) => {
      if (contextList[contextIdKey].isSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete contextList[contextIdKey];
      }
    });

    setState({
      [stateKey]: {
        ...currentState,
        [KEY_CONTEXT_LIST]: { ...contextList },
      },
    });
  };

/**
 * Set context list id in the context list if it not exists.
 *
 * @param {string} stateKey
 * @param {string} contextListId
 * @returns {function(...[*]=)}
 */
const setContextListIdIfNotExists =
  (stateKey, contextListId) =>
  ({ getState, setState, dispatch }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    if (!(contextListId in contextList)) {
      contextList[contextListId] = createContextObject();

      setState({
        [stateKey]: {
          ...currentState,
          [KEY_CONTEXT_LIST]: { ...contextList },
        },
      });

      dispatch(dispatchSetGlobalFetchUpdate(stateKey));
    }
  };
// #endregion

/**
 * Reset the sweet fetch state
 *
 * @param {string} stateKey
 * @param {string} contextListId
 * @returns {function(...[*]=)}
 */
export const stateReset =
  (stateKey, contextListId = DEFAULT_CONTEXT_LIST_ID) =>
  ({ getState, setState, dispatch }) => {
    const currentState = getState()[stateKey];
    const contextList = { ...currentState[KEY_CONTEXT_LIST] };
    contextList[contextListId] = createContextObject();

    setState({
      [stateKey]: {
        ...currentState,
        [KEY_CONTEXT_LIST]: { ...contextList },
      },
    });

    dispatch(dispatchSetGlobalFetchUpdate(stateKey));
  };

/**
 * Sweet fetch state create handling with context
 *
 * @param {string} stateKey
 * @param {string} contextListId
 * @param {function} dispatch
 * @param {function} getState
 * @returns {doRequest, isBusy, setErrorMessage, setContext}
 */
export const sweetFetchStateWithContextId = (stateKey, contextListId, dispatch, getState) => {
  // force global fetch update dispatch
  dispatch(setContextListIdIfNotExists(stateKey, contextListId));

  /**
   * Do fetch request function
   *
   * @param {Promise} fetchPromise
   * @returns {Promise<unknown>}
   */
  const doRequest = (fetchPromise) => {
    return new Promise((resolve, reject) => {
      dispatch(dispatchSetStateToBusy(stateKey, contextListId));
      fetchPromise
        .then((response) => {
          dispatch(dispatchSetStateToSuccess(stateKey, contextListId, response));
          resolve(response);
        })
        .catch((response) => {
          dispatch(dispatchSetStateToError(stateKey, contextListId, response));
          reject(response);
        });
    });
  };

  /**
   * Set fetch state context
   * @param {object} context
   *
   * @returns {*}
   */
  const setContext = (context) =>
    dispatch(dispatchSetContextState(stateKey, contextListId, context));

  /**
   * Set error message for fetch state
   * @param {string} message
   * @returns {*}
   */
  const setErrorMessage = (message) =>
    dispatch(dispatchSetErrorMessageState(stateKey, contextListId, message));

  /**
   * Returns busy state of fetch state
   *
   * @returns {*}
   */
  const isBusyState = () => getState()[stateKey][KEY_CONTEXT_LIST][contextListId].isBusy;

  /**
   * Cleanup context list state
   *
   * @returns {*}
   */
  const cleanup = () => dispatch(dispatchSetCleanupContextList(stateKey));

  /**
   * Set fetch state context
   * @param {object} context
   *
   * @returns {*}
   */
  const getContext = (context) => getState()[stateKey][KEY_CONTEXT_LIST][contextListId].context;

  return {
    doRequest,
    setContext,
    getContext,
    isBusy: isBusyState,
    setErrorMessage,
    cleanup,
  };
};

/**
 * Sweet fetch state with only 1 context, usefull for single context id needed.
 *
 * @param {string} stateKey
 * @param {function} dispatch
 * @param {function} getState
 * @returns {doRequest, isBusy, setErrorMessage, setContext}
 */
export const sweetFetchState = (stateKey, dispatch, getState) => {
  return sweetFetchStateWithContextId(stateKey, DEFAULT_CONTEXT_LIST_ID, dispatch, getState);
};

/**
 * Get fetch state for a specific context list id
 *
 * @param {Object} fetchState
 * @param {String} contextListId
 * @returns {{
 * isBusy,
 * errorContext,
 * response,
 * context,
 * errorMessage,
 * errorCode,
 * hasError,
 * currentState,
 * errorData,
 * isSuccess:
 * }}
 */
export const sweetFetchStateForContextID = (
  fetchState,
  contextListId = DEFAULT_CONTEXT_LIST_ID,
) => {
  if (contextListId in fetchState[KEY_CONTEXT_LIST]) {
    return fetchState[KEY_CONTEXT_LIST][contextListId];
  } else {
    return createContextObject();
  }
};

/**
 * Default exports
 */
export default {
  init,
  reset: stateReset,
  withContextId: sweetFetchStateWithContextId,
  forContextId: sweetFetchStateForContextID,
  state: sweetFetchState,
};
