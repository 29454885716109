import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

export default class OwnSourcesApi extends APIEndpoint {
  /**
   * Overview of all own sources.
   *
   * @param {array} [filters=[]]
   * @param {string} [sort='']
   * @param {number} [page=1]
   * @param {number} [pageSize=10]
   * @return {JSendResponse}
   */
  overview(filters = [], sort = '', page = 1, pageSize = 10) {
    const url = this.buildUrl('own_sources', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Details of a single own source.
   *
   * @param {string} id
   * @return {JSendResponse}
   */
  details(id) {
    const url = this.buildUrl(`own_sources/${id}`);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Create an own source.
   *
   * @param {object} values
   * @return {JSendResponse}
   */
  create(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'own_sources',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update an own source.
   *
   * @param {string} id
   * @param {object} values
   * @return {JSendResponse}
   */
  update(id, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `own_sources/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete an own source.
   *
   * @param {string} id
   * @return {JSendResponse}
   */
  delete(id) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `own_sources/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
