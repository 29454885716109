import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Cloud browser API class
 */
export default class CloudBrowserAPI extends APIEndpoint {
  /**
   * Get cloud filelist for user or admin
   *
   * @param {string} userType
   * @param {string|undefined} itemId
   * @returns {Promise}
   */
  getFileList(userType, itemId) {
    let url = userType === 'admin' ? 'cloud/browser/admin' : 'cloud/browser';
    if (itemId) {
      url += `/${itemId}`;
    }

    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }
}
