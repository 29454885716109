import packageInfo from '../../package.json';

// #region read environment variables
// the only environment values that are being read.
const readEnvironmentKeys = [
  'REACT_APP_API_HOST',
  'REACT_APP_LOGIN_HUB_HOST',
  'REACT_APP_PROFILE_URL',
  'REACT_APP_ONE_DRIVE_CLIENT_ID',
  'REACT_APP_NDFR_AUTH_URL',
  'REACT_APP_NDFR_SEARCH_URL',
  'REACT_APP_TOOLS_URL',
  'REACT_APP_SEARCH_ENABLED',
  'REACT_APP_NDFR_DOMAIN',
  'REACT_APP_CRM_DISALLOW_NEW',
  'REACT_APP_TASK_REPORTS_ENABLED',
];
// taxViceConfig comes from the config.js that is located in /public folder.
// we make an empty object is it is not defined.
const taxViceConfig = (typeof window !== 'undefined' && window.taxviceConfig) || {};
const envVariables = {};
readEnvironmentKeys.forEach((environmentItem) => {
  // window.taxviceConfig overrides the process.env variable.
  envVariables[environmentItem] = taxViceConfig[environmentItem] || process.env[environmentItem];
});
// #endregion

const apiHost = envVariables.REACT_APP_API_HOST ?? '';

export default {
  APPLICATION_API_HOST: apiHost,
  APPLICATION_API_BASE_HOST: apiHost.replace('api/v1/', ''),
  APPLICATION_LOGIN_HUB_HOST: envVariables.REACT_APP_LOGIN_HUB_HOST,
  APPLICATION_PROFILE_URL: envVariables.REACT_APP_PROFILE_URL,
  APPLICATION_ENVIRONMENT: process.env.NODE_ENV,
  APPLICATION_VERSION: packageInfo.version,
  ONE_DRIVE_CLIENT_ID: envVariables.REACT_APP_ONE_DRIVE_CLIENT_ID,
  NDFR_AUTH_URL: envVariables.REACT_APP_NDFR_AUTH_URL || 'https://api-auth.acc.titan.awssdu.nl',
  // eslint-disable-next-line max-len
  NDFR_SEARCH_URL: `${apiHost}sws_content/graphql`,
  // eslint-disable-next-line max-len
  APPLICATION_TOOL_URL:
    envVariables.REACT_APP_TOOLS_URL ||
    'https://taxvice-content.sdu.nl/nl/search?facets.type[]=tools&page=1',
  APPLICATION_SEARCH_ENABLED: envVariables.REACT_APP_SEARCH_ENABLED === 'true',
  APPLICATION_NDFR_DOMAIN: envVariables.REACT_APP_NDFR_DOMAIN,
  CRM_DISALLOW_NEW: envVariables.REACT_APP_CRM_DISALLOW_NEW === 'true',
  UPLOAD_MAX_FILES: 20,
  TASK_REPORTS_ENABLED: envVariables.REACT_APP_TASK_REPORTS_ENABLED === 'true',
};
