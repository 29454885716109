import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * User
 */
export default class User extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    email: PropTypes.string,
    fullName: PropTypes.string,
    avatarUrl: PropTypes.string,
  };

  /**
   * User email.
   * @type {string}
   */
  email;

  /**
   * User fullName.
   */
  fullName;

  /**
   * User avatar.
   * @type {string}
   */
  avatarUrl;

  /**
   * @returns {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   * @returns {string}
   */
  getFullName() {
    return this.fullName;
  }

  /**
   * @returns {string}
   */
  getAvatarUrl() {
    return this.avatarUrl;
  }

  /**
   * Create user mention object for user suggestions.
   *
   * @returns {{avatarUrl: string, name: string, id: (string|null)}}
   */
  getUserMentionObject() {
    return {
      id: this.getIdentity(),
      name: this.getFullName(),
      avatar: this.getAvatarUrl(),
    };
  }

  /**
   * Create selection option object for UserSelectField component
   *
   * @returns {{image: string, label: string, type: string, value: (string|null), email: string}}
   */
  getSelectOptionObject() {
    return {
      value: this.getIdentity(),
      label: this.getFullName(),
      image: this.getAvatarUrl(),
      email: this.getEmail(),
      type: 'person',
      user: this,
    };
  }
}
