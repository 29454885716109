import { Box, Clickable, CloseIcon, Flex, IconButton, Text } from '@elseu/sdu-titan';
import React from 'react';
import styled, { css } from 'styled-components';

import { NotificationTypeIcon } from './NotificationTypeIcon';
import type { NotificationItem } from './types';

export type NotificationListItemProps = {
  item: NotificationItem;
  type: 'list' | 'toast';
  isClickable?: boolean;
  onDismiss: () => void;
  onClick?: () => void;
};

type ClickableElementProps = React.PropsWithChildren<{
  isClickable: boolean;
  onClick?: () => void;
}>;

const IconContainer = styled.div`
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClickableOverride = styled(Clickable)`
  text-align: left;
  display: block;
  width: 100%;
`;

const NotificationContainer = styled(Box)<{ type: NotificationListItemProps['type'] }>`
  ${({ type, theme }) => {
    if (type === 'toast') {
      return css`
        box-shadow: ${theme.elevationDown[3]} ${theme.elevationDown[3]};
      `;
    }
  }}
`;

const ClickableElement: React.FC<ClickableElementProps> = ({ isClickable, onClick, children }) => {
  return isClickable ? (
    <ClickableOverride type="button" onClick={onClick}>
      {children}
    </ClickableOverride>
  ) : (
    <>{children}</>
  );
};

export const NotificationListItem: React.FC<NotificationListItemProps> = ({
  item,
  type = 'list',
  isClickable = false,
  onClick,
  onDismiss,
}) => (
  <ClickableElement isClickable={isClickable} onClick={onClick}>
    <NotificationContainer
      backgroundColor={type === 'list' ? 'grey5' : 'grey0'}
      borderRadius={1}
      px={6}
      py={4}
      spaceAfter={2}
      type={type}
    >
      <Flex alignItems="center" gap={2} spaceAfter={6}>
        <IconContainer>
          <NotificationTypeIcon icon={item.type} isViewed={item.isViewed} />
        </IconContainer>
        <Flex flex={1}>
          <Text color="grey80" type="labelLargeBold">
            {item.title}
          </Text>
        </Flex>
        <IconContainer>
          <IconButton
            color="grey80"
            icon={<CloseIcon color="grey80" />}
            onClick={(evt) => {
              evt.stopPropagation();
              onDismiss();
            }}
          />
        </IconContainer>
      </Flex>
      <Text color="grey80" type="paragraph">
        {item.body}
      </Text>
    </NotificationContainer>
  </ClickableElement>
);
