import React from 'react';
import { resolveValue, useToaster } from 'react-hot-toast/headless';
import styled, { css, keyframes } from 'styled-components';

import { ToastNotification } from './ToastNotification';

const enterAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%) scale(0.25);    
  }
  50% {
    opacity: 1;
    transform: translateX(0%) scale(0.5);    
  }
  75% {
    opacity: 0.5;
    transform: translateX(0%) scale(0.5);    
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1.0);
  }
`;

const exitAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0) scale(1.0);
  }
  25% {
    opacity: 1;
    transform: translateX(0%) scale(0.25);    
  }  
  50% {
    opacity: 0.5;
    transform: translateX(0%) scale(0.25);    
  }
  100% {
    opacity: 0;
    transform: translateX(100%) scale(0.25);    
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  right: 0;
  top: 80px;
  margin: 1.5rem;
  z-index: 1000003;
`;

const ToastContainerItem = styled.div<{ isVisible: boolean; offset: number }>`
  position: 'absolute';
  width: 400px;

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${enterAnimation} 0.25s ease-out;
        `
      : css`
          animation: ${exitAnimation} 0.25s ease-out forwards;
        `}
`;

export const NotificationToasterPlacement: React.FC = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <ToastContainer onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((toastItem) => {
        const offset = calculateOffset(toastItem, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el: any) => {
          if (el && typeof toastItem.height !== 'number') {
            const height = el.getBoundingClientRect().height;
            updateHeight(toastItem.id, height);
          }
        };

        return (
          <ToastContainerItem
            key={toastItem.id}
            ref={ref}
            isVisible={toastItem.visible}
            offset={offset}
            {...toastItem.ariaProps}
          >
            {typeof toastItem.message === 'string' ? (
              <ToastNotification
                item={{
                  id: '',
                  type: 'default',
                  title: 'Notificatie',
                  body: toastItem.message,
                  url: undefined,
                  createdAt: new Date(),
                  isViewed: false,
                }}
                toastItem={toastItem}
              />
            ) : (
              resolveValue(toastItem.message, toastItem)
            )}
          </ToastContainerItem>
        );
      })}
    </ToastContainer>
  );
};
