// import polyfills for IE.
import 'react-app-polyfill/ie9';
import 'assets/styles/Index.scss';
import 'assets/styles/Fonts.scss';
import 'assets/styles/Colors.scss';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import constants from 'constants/ApplicationConstants';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { defaults } from 'react-sweet-state';

import App from './App';

// only enable development tools for react-sweet-state when in development mode.
defaults.devtools = constants.APPLICATION_ENVIRONMENT === 'development';

if (constants.APPLICATION_ENVIRONMENT !== 'development') {
  // setup Sentry
  Sentry.init({
    dsn: 'https://cdaab599233441ffbebdd21a32c0de85@sentry.wecreatesolutions.nl/9',
    integrations: [new BrowserTracing()],
    environment: constants.APPLICATION_ENVIRONMENT,
    release: constants.APPLICATION_VERSION,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
      "Cannot read properties of null (reading 'getBoundingClientRect')",
    ],
    tracesSampleRate: 1.0,
  });

  // configure Sentry scope
  Sentry.configureScope(function (scope) {
    scope.setTag('site', document.location.hostname);
    scope.setTag('product_name', 'Sdu Addify');
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
