import * as Sentry from '@sentry/react';
import Actions from 'actions/Actions';
import Me from 'models/me/Me';
import { createHook, createStore } from 'react-sweet-state';
import LocalStorageService from 'services/LocalStorageService';
import sweetFetchState from 'stores/utils/SweetFetchState';

/**
 * Get token
 * @returns {string}
 */
const getToken = () => {
  return LocalStorageService.getItem('token', null);
};

// #region all states that hold data
export const STATE_AUTHENTICATED_FETCH_STATE = 'authenticatedFetchState';
export const STATE_ME = 'me';
export const STATE_ME_FETCH_STATE = 'meFetchState';
export const STATE_NOTIFICATION_SETTINGS_FETCH_STATE = 'notificationSettingsFetchState';
export const STATE_AVATAR_UPLOAD_FETCH_STATE = 'avatarUploadFetchState';
// #endregion

/**
 * Sets a new me state
 *
 * @param {Me} newMeState
 * @returns {function(...[*]=)}
 */
const setNewMeState =
  (newMeState) =>
  ({ setState }) => {
    setState({ [STATE_ME]: newMeState });
  };

const setupSentry = (me) => {
  // setup sentry data
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: me.profile.id,
      username: me.profile.email,
    });
    scope.setTag('site', document.location.hostname);
    scope.setTag('product_name', 'Sdu Addify');
  });
};

/**
 * Set initial state
 *
 * @returns {{}}
 */
const initialState = () => {
  const token = getToken();

  return {
    // authentication
    token,
    authenticated: !!token,
    justLoggedOut: false,
    needToReAuth: false,
    showMeUpdateIndicator: false,
    // me profile
    [STATE_ME]: Me.createInstance({}), // @todo refactor this to fetch state with context...
    // fetch states
    [STATE_AUTHENTICATED_FETCH_STATE]: sweetFetchState.init(),
    [STATE_ME_FETCH_STATE]: sweetFetchState.init(),
    // avatar uploading
    [STATE_AVATAR_UPLOAD_FETCH_STATE]: sweetFetchState.init(),
    // notification settings
    [STATE_NOTIFICATION_SETTINGS_FETCH_STATE]: sweetFetchState.init(),
  };
};

// state actions
const actions = {
  // #region authentication
  /**
   * Validate login code
   *
   * @param {string} loginCode
   * @returns {function(...[*]=)}
   */
  validateLoginCode:
    (loginCode) =>
    ({ setState, getState, dispatch }) => {
      return new Promise((resolve, reject) => {
        const { doRequest, isBusy, setErrorMessage } = sweetFetchState.state(
          STATE_AUTHENTICATED_FETCH_STATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        // set base state
        setState({
          authenticated: false,
          token: false,
          justLoggedOut: false,
          needToReAuth: false,
          [STATE_ME]: Me.createInstance(),
        });

        doRequest(Actions.getAPIService().validateLoginCode(loginCode))
          .then((response) => {
            const data = response.data || response;
            const token = data.token;
            const me = Me.createInstance(data.me);

            LocalStorageService.setItem('token', token);

            dispatch(setNewMeState(me));
            setState({
              token,
              authenticated: true,
            });
            setupSentry(me);
            resolve();
          })
          .catch((response) => {
            const { message } = response;
            const translations = {
              'Bad credentials.': 'Login en/of wachtwoord niet bekend',
              'Authentication Required': 'Authentication via login is nodig.',
              'Invalid token': 'Ongeldige inlog code.',
              'Login code expired': 'Inlog code verlopen.',
              'Unable to find user for login code':
                'Kan gebruiker niet vinden met opgegeven logincode',
            };
            const errorMessage = (message && translations[message]) || message || false;
            setErrorMessage(errorMessage);
            reject(new Error(errorMessage));
          });
      });
    },

  /**
   * Logout
   *
   * @returns {Promise}
   */
  logout:
    () =>
    ({ setState, getState, dispatch }) => {
      return new Promise((resolve, reject) => {
        const { doRequest, isBusy } = sweetFetchState.state(
          STATE_AUTHENTICATED_FETCH_STATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().logout()).then(
          () => {
            LocalStorageService.removeItem('token');

            setState({
              authenticated: false,
              justLoggedOut: true,
              needToReAuth: false,
              token: false,
            });
            dispatch(setNewMeState(false));
            resolve();
          },
          () => reject(new Error('Failed to logout')),
        );
      });
    },
  // #endregion

  // #region settings
  /**
   * Get settings for user
   *
   * @param {boolean} update
   * @returns {function(...[*]=)}
   */
  getSettings:
    (update = false) =>
    ({ setState, getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.state(STATE_ME_FETCH_STATE, dispatch, getState);

      if (isBusy()) {
        return;
      }

      // set loading state and reset me state.
      if (update) {
        // only do when update loading is true
        setState({ showMeUpdateIndicator: false });
      } else {
        setState({ me: Me.createInstance(), showMeUpdateIndicator: true });
      }

      doRequest(Actions.getAPIService().settings())
        .then((response) => {
          const me = Me.createInstance(response.data);

          dispatch(setNewMeState(me));
          setupSentry(me);
        })
        .catch((jSendResponse) => {
          if (jSendResponse?.getCode && jSendResponse.getCode() === 401) {
            // check in the state if we are authenticated.
            // If so that possible means that we need to do re-authentication with the hub.
            const needToReAuth = getState().authenticated && !!getState().token;

            if (needToReAuth) {
              // remove token for local storage. This is to prevent looping of authentication
              LocalStorageService.removeItem('token');
            }

            setState({
              authenticated: false,
              token: false,
              needToReAuth,
            });
          }
        });
    },

  /**
   * Update the settings for user notifications.
   *
   * @param {object} notification
   * @returns {function(...[*]=)}
   */
  updateNotificationSettings:
    (notification) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.state(
        STATE_NOTIFICATION_SETTINGS_FETCH_STATE,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().updateNotificationSettings(notification)).then(
        (response) => {
          dispatch(setNewMeState(Me.createInstance(response.data)));
        },
        () => null,
      );
    },
  // #endregion

  // #region avatar
  /**
   * Upload avatar for the currently logged-in user.
   *
   * @param {object} avatarFile
   * @returns {function(...[*]=)}
   */
  uploadAvatar:
    (avatarFile) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.state(
        STATE_AVATAR_UPLOAD_FETCH_STATE,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().uploadAvatar(avatarFile)).then(
        (response) => {
          dispatch(setNewMeState(Me.createInstance(response.data)));
        },
        () => null,
      );
    },

  // #endregion
};

// create store
const Store = createStore({
  name: 'AccountState',
  initialState: initialState(),
  actions,
});

// create store hook
export const useAccountState = createHook(Store);
