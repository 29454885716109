import DataModel, { PropTypes as ModelPropTypes } from 'models/base/DataModel';

import Profile from './Profile';
import Settings from './Settings';

/**
 * Me
 */
export default class Me extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    settings: ModelPropTypes.shape(Settings.propTypes),
    profile: ModelPropTypes.shape(Profile.propTypes),
  };

  /**
   * Profile data containing details about the logged in account.
   * @type {Profile}
   */
  profile = Profile.createInstance();

  /**
   * Settings for the application.
   * @type {Settings}
   */
  settings;

  /**
   * @returns {Profile}
   */
  getProfile() {
    return this.profile;
  }

  /**
   * @returns {Settings}
   */
  getSettings() {
    return this.settings;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'profile') {
      value = Profile.createInstance(value);
    } else if (fieldName === 'settings') {
      value = Settings.createInstance(value);
    }

    return super.set(fieldName, value);
  }
}
