import clone from 'clone';
import * as dotProp from 'dot-prop';
import { PropTypes, validate } from 'proptypes-schema';
import warning from 'warning';

export { PropTypes, validate } from 'proptypes-schema';

/**
 * Model
 *
 * Base model class that allows us to set up models with custom prop type schema verification.
 */
export default class DataModel {
  /**
   * Configuration for model properties, this allows you to specify the value and require state of a
   * model property value.
   * @type {{id:{Number}}}
   */
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  /**
   * @type {null|string}
   */
  id = null;

  /**
   * Class constructor.
   * @param {Object} props
   */
  constructor(props = {}) {
    // merge given properties with default properties to make sure that all required default
    // properties where assigned to the model storage.
    this.setValues(Object.assign({}, this.getValues(), props));
  }

  /**
   * Pass an object and fill all properties that where defined in the prop types of the model,
   * ignore all other.
   * @param {Object|undefined} props
   * @returns {self}
   */
  setValues(props) {
    if (props) {
      this.getPropNames().forEach((prop) => {
        if (props[prop] !== undefined) {
          this.set(prop, props[prop]);
        }
      });

      const warnings = this.getValidationWarnings();
      warning(
        warnings === undefined,
        `Warning: Validation of properties failed, please check ${Object.keys(
          warnings === undefined ? {} : warnings,
        ).join(', ')} in ` +
          `${this.constructor.name}, and make sure that all default values are present.`,
      );
    }
    return this;
  }

  /**
   * Assign the value for a certain field, this allows us to transform certain field storage into
   * other structures as we like.
   * @param {string} fieldName
   * @param {*} value
   * @returns {DataModel}
   */
  set(fieldName, value) {
    this[fieldName] = value;
    return this;
  }

  /**
   * Assign the value for a certain field with dotProp package. So for example item.subitem is possible.
   *
   * @param {string} fieldName
   * @param {*} value
   * @returns {DataModel}
   */
  setDotValue(fieldName, value) {
    dotProp.setProperty(this, fieldName, value);
    return this;
  }

  /**
   * Retrieve all property storage of this model in an object, all property names are keys.
   * @returns {Object}
   */
  getValues() {
    const values = {};
    this.getPropNames().forEach((prop) => {
      values[prop] = this[prop];
    });
    return values;
  }

  /**
   * Retrieve an indicator if the internal storage match the configured schema that is present
   * within propTypes.
   * @return {boolean}
   */
  isValid() {
    return this.getValidationWarnings() === undefined;
  }

  /**
   * Retrieve the validation warning message
   * @return {object|undefined}
   */
  getValidationWarnings() {
    return validate(this.getPropTypes(), this.getValues());
  }

  /**
   * Retrieve all configured prop types for this model.
   * @return {Object}
   */
  getPropTypes() {
    // eslint-disable-next-line
    return this.constructor.propTypes;
  }

  /**
   * Retrieve all property names this model accepts.
   * @return {Array}
   */
  getPropNames() {
    return Object.keys(this.getPropTypes());
  }

  /**
   * Retrieve a copied version of this model
   * @return {self}
   */
  copy() {
    return clone(this);
  }

  /**
   * Unique identity of this model.
   * @return {string|null}
   */
  getIdentity() {
    return this.id;
  }

  /**
   * Create a new model instance filled with these props.
   * @param {Object} props
   * @return {self}
   */
  static createInstance(props = {}) {
    return new this().setValues(props);
  }

  /**
   * Create multiple instances of this model based on the property objects passed in the argument
   * array.
   * @param {Array} array
   * @return {self[]}
   */
  static createInstancesByArray(array) {
    const retValue = [];
    if (Array.isArray(array)) {
      array.forEach((props) => {
        retValue.push(this.createInstance(props));
      });
    }
    return retValue;
  }

  /**
   * Retrieve an indicator if the given model object is actually implementing this class.
   * @param {*} model
   * @return {boolean}
   */
  static instanceOf(model) {
    return validate({ model: PropTypes.instanceOf(DataModel) }, { model }) === undefined;
  }
}
