import type { Paging } from './types';

export const pagingDefaultState = (): Paging => ({
  previousPage: 1,
  page: 1,
  nextPage: 1,
  maxPerPage: 25,
  numberOfResults: 0,
  totalPages: 1,
});

export const normalizePaging = (result: any): Paging => ({
  previousPage: result?.previousPage,
  page: result?.page,
  nextPage: result?.nextPage,
  maxPerPage: result?.maxPerPage,
  numberOfResults: result?.numberOfResults,
  totalPages: result?.totalPages,
});
