import React, { useEffect, useState } from 'react';

import TopLoader from './TopLoader';

// time to show loader!
const LAZY_LOADER_DELAY = 500;
const LazyLoader = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowLoader(true), LAZY_LOADER_DELAY);
    return () => clearTimeout(timeout);
  }, []);

  return showLoader ? <TopLoader /> : null;
};

export default LazyLoader;
