import './assets/styles/OneDriveUserAuthChecker.scss';

import Actions from 'actions/Actions';
import Button from 'components/button/Button';
import HighlightBox, { IHighlightBoxAppearance } from 'components/highlightbox/HighlightBox';
import Modal from 'components/modal/Modal';
import ApplicationConstants from 'constants/ApplicationConstants';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * OneDriveUserAuthChecker
 */
class OneDriveUserAuthChecker extends Component {
  state = {
    authNotification: {
      show: false,
      message: null,
      type: IHighlightBoxAppearance.APPEARANCE_DEFAULT,
    },
  };

  refreshTokenTimeout = 900000; // refresh every 15 minutes
  refreshTokenTimer = null;

  /**
   * Open sign in click
   */
  signInClick() {
    // we first create a empty window. because else it will be blocked by the popup-blocker.
    const signInWindow = window.open('', 'oneDriveLoginUser', 'width=1024, height=768');
    Actions.getAPIService()
      .oneDrive()
      .userLoginUrl()
      .then((data) => {
        if (data.status === 'success') {
          signInWindow.location.href = data.data.loginUrl;
        } else {
          this.setAuthNotification({
            show: true,
            type: IHighlightBoxAppearance.APPEARANCE_RED,
            message: data.message || 'Er ging iets niet goed bij het aanvragen van login url',
          });
          signInWindow.close();
        }
      })
      .catch((reason) => {
        this.setAuthNotification({
          show: true,
          type: IHighlightBoxAppearance.APPEARANCE_RED,
          message: `Er ging iets niet goed bij het aanvragen van login url (${
            reason.message || 'Onbekende fout'
          })`,
        });
        signInWindow.close();
      });
  }

  /**
   * Set new notification state
   *
   * @param {object} state
   */
  setAuthNotification(state) {
    this.setState({ authNotification: state });
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    const { onNeedToRefreshToken } = this.props;
    this.messageListener = window.addEventListener('message', this.onWindowMessage.bind(this));
    this.refreshTokenTimer = setInterval(() => {
      if (onNeedToRefreshToken) {
        onNeedToRefreshToken();
      }
    }, this.refreshTokenTimeout);
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener);
    clearInterval(this.refreshTokenTimer);
  }

  /**
   * Messages from window event listener are handled here.
   *
   * @param event
   */
  onWindowMessage(event) {
    if (`${event.origin}/` !== ApplicationConstants.APPLICATION_API_BASE_HOST) {
      return;
    }

    const data = event.data;
    const { doProfileRefresh } = this.props;
    if (data instanceof Object) {
      switch (data.type) {
        case 'onedrive_auth_message':
          this.setAuthNotification({
            show: true,
            message: data.message,
            type: data.message_type,
          });
          break;
        case 'onedrive_reload_settings':
          if (doProfileRefresh) {
            doProfileRefresh();
          }
          break;
        default:
      }
    }
  }

  /**
   * @inheritDoc
   */
  render() {
    const { isPluginActive, meProfile } = this.props;
    const { authNotification } = this.state;

    // check if the plugin is active.
    if (!isPluginActive) {
      return null;
    }

    // check if the user is authenticated.
    if (meProfile?.isOneDriveUserActive) {
      return null;
    }

    return (
      <Modal
        shouldUsePortal
        className="c-one-drive-user-auth-checker"
        header={{
          title: 'Office365 authenicatie vereist.',
        }}
      >
        {authNotification.show && (
          <HighlightBox
            hasBorder
            appearance={authNotification.type}
            className="c-one-drive-user-auth-checker__highlight-box"
          >
            {authNotification.message}
          </HighlightBox>
        )}
        <p>Uw beheerder van Addify heeft Office-365 geactiveerd.</p>
        <p>U dient eerst uw Microsoft account te koppelen aan Addify alvorens u verder kunt.</p>
        <div className="c-one-drive-user-auth-checker__buttons">
          <Button onClick={this.signInClick.bind(this)}>Autoriseer</Button>
        </div>
      </Modal>
    );
  }
}

OneDriveUserAuthChecker.propTypes = {
  isPluginActive: PropTypes.bool,
  meProfile: PropTypes.object,
  onNeedToRefreshToken: PropTypes.func,
  doProfileRefresh: PropTypes.func,
};

export default OneDriveUserAuthChecker;
