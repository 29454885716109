import DataModel, { PropTypes } from 'models/base/DataModel';
import SubOffice from 'models/system/SubOffice';

/**
 * Team model
 */
export default class Team extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    description: PropTypes.string,
    countUsers: PropTypes.number,
    countClients: PropTypes.number,
    createdDateTime: PropTypes.string,
    subOffices: PropTypes.arrayOf(PropTypes.shape(SubOffice.propTypes)),
    roleInGroupOfCurrentUser: PropTypes.string,
    isDeleted: PropTypes.bool,
    permissions: PropTypes.shape({
      canView: PropTypes.bool,
      canEdit: PropTypes.bool,
      canDelete: PropTypes.bool,
      canUserOverview: PropTypes.bool,
      canUserAdd: PropTypes.bool,
      canUserRemove: PropTypes.bool,
      canClientOverview: PropTypes.bool,
      canClientAdd: PropTypes.bool,
      canClientRemove: PropTypes.bool,
    }),
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  description;

  /**
   * @type {number}
   */
  countUsers;

  /**
   * @type {number}
   */
  countClients;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {string|undefined}
   */
  roleInGroupOfCurrentUser;

  /**
   * @type {Object}
   */
  permissions;

  /**
   * @type {SubOffice[]}
   */
  subOffices;

  /**
   * @type {boolean}
   */
  isDeleted = false;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getDescription() {
    return this.description;
  }

  /**
   * @returns {number}
   */
  getCountUsers() {
    return this.countUsers;
  }

  /**
   * @returns {number}
   */
  getCountClients() {
    return this.countClients;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {string|undefined}
   */
  getRoleInGroupOfCurrentUser() {
    return this.roleInGroupOfCurrentUser;
  }

  /**
   * @return {SubOffice[]}
   */
  getSubOffices() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.subOffices || [];
  }

  /**
   * @returns {boolean}
   */
  getIsDeleted() {
    return this.isDeleted;
  }

  /**
   * Get permissions
   *
   * @returns {Object}
   */
  getPermissions() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.permissions === undefined) {
      this.permissions = {
        canView: false,
        canEdit: false,
        canDelete: false,
        canUserOverview: false,
        canUserAdd: false,
        canUserRemove: false,
        canClientOverview: false,
        canClientAdd: false,
        canClientRemove: false,
      };
    }
    return this.permissions;
  }

  /**
   * Create selection option object for UserSelectField component
   *
   * @returns {{image: string, label: string, type: string, value: (string|null), email: string}}
   */
  getSelectOptionObject() {
    return {
      value: this.getIdentity(),
      label: this.getName(),
      type: 'team',
      team: this,
    };
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'subOffices') {
      value = SubOffice.createInstancesByArray(value);
    }
    return super.set(fieldName, value);
  }
}
