import { Logo, textStyle } from '@elseu/sdu-titan';
import React from 'react';
import styled, { css } from 'styled-components';

const BrandName = styled.span`
  ${textStyle('labelTinyBold')};
  font-family: ${({ theme }) => theme.components.logo.fontFamily};
  color: ${({ theme }) => theme.components.logo.textColor};
  margin-left: ${({ theme }) => theme.components.logo.brandMargin};
`;

const sharedLogoStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.components.logo.brandColor};
  padding: ${({ theme }) => theme.components.logo.logoPadding} 0;
`;

const BrandHolder = styled.div`
  ${sharedLogoStyles}
`;

const BrandImage = styled.img`
  height: 32px;
  width: 32px;
`;

const StyledLogoLink = styled.a`
  ${sharedLogoStyles}
`;

type Props = {
  className?: string;
  brandName?: string;
  brandImage?: string;
  isClickable?: boolean;
};

const ApplicationTitle: React.FC<Props> = ({
  className,
  brandName,
  brandImage,
  isClickable = true,
}) => {
  const brandNameDisplay = brandName || 'ADDIFY';
  if (brandImage === undefined) {
    return (
      <Logo brandName={brandName || 'ADDIFY'} onClick={!isClickable ? () => null : undefined} />
    );
  }

  return isClickable ? (
    <StyledLogoLink className={className} href="/">
      <BrandImage src={brandImage} />
      <BrandName>{brandNameDisplay}</BrandName>
    </StyledLogoLink>
  ) : (
    <BrandHolder className={className}>
      <BrandImage src={brandImage} />
      <BrandName>{brandNameDisplay}</BrandName>
    </BrandHolder>
  );
};

export default ApplicationTitle;
