/**
 * JSendResponse wrapper
 * We Create Solutions B.V.
 * @see https://labs.omniti.com/labs/jsend.
 */
export default class JSendResponse {
  /**
   * JSend standard key names.
   * @type {string}
   */
  static STATUS = 'status';
  static DATA = 'data';
  static MESSAGE = 'message';
  static CODE = 'code';

  /**
   * JSend status codes @see https://labs.omniti.com/labs/jsend
   * @type {string}
   */
  static STATUS_SUCCESS = 'success'; // All went well, and (usually) some data was returned.
  // There was a problem with the data submitted,
  // or some pre-condition of the API call wasn't satisfied
  static STATUS_FAIL = 'fail';
  static STATUS_ERROR = 'error'; // An error occurred in processing the request, i.e. an exception was thrown

  /**
   * Actual success, failure or error status that the response has, should always be present.
   * @type {string}
   */
  status = JSendResponse.STATUS_SUCCESS;

  /**
   * Message that will give more information about the error, only present if the status is error.
   * @type {null|string}
   */
  message = null;

  /**
   * Code that will only be present when the status is error.
   * @type {null|number}
   */
  code = null;

  /**
   * Statistics contains any extra data, this is required in the case of a success or fail status, error is optional.
   * @type {null|object}
   */
  data = {};

  /**
   * Parse a given object into a JSendResponse object.
   * @param {object} object
   * @return {JSendResponse}
   */
  static fromObject(object) {
    return new JSendResponse(
      object[JSendResponse.STATUS],
      object[JSendResponse.MESSAGE] || null,
      object[JSendResponse.DATA] || null,
      object[JSendResponse.CODE] || null,
    );
  }

  /**
   *
   * @param {object} error
   * @return {JSendResponse}
   */
  static fromError(error) {
    return new JSendResponse(
      JSendResponse.STATUS_ERROR,
      error.message,
      error.data || null,
      error.code || null,
    );
  }

  /**
   * Class constructor
   *
   * @param {string} status
   * @param {null|string} message
   * @param {null|object} data
   * @param {null|number} code
   */
  constructor(status = JSendResponse.STATUS_SUCCESS, message = null, data = null, code = null) {
    // sanity check if the given status is one of the supported jsend statuses.
    const supportedStatuses = [
      JSendResponse.STATUS_SUCCESS,
      JSendResponse.STATUS_ERROR,
      JSendResponse.STATUS_FAIL,
    ];
    if (!supportedStatuses.includes(status)) {
      throw new Error(
        `JSend package has an invalid status, given status ${status} is not one of: "${supportedStatuses.implode(
          '", "',
        )}".`,
      );
    }
    this.status = status;

    // data is optional in case of status error, status success and fail should contain the data key.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (![JSendResponse.STATUS_ERROR].includes(status) && data === undefined) {
      throw new Error(
        `JSend package has an invalid format, given status "${status}" 
        indicates that a data key is required, see https://labs.omniti.com/labs/jsend.`,
      );
    } else {
      // upon error a message is required
      this.message = message;
    }

    this.data = data || null;
    this.code = code;
  }

  /**
   * Whether this was a response that was successful.
   * @return {boolean}
   */
  isSuccess() {
    return [JSendResponse.STATUS_SUCCESS].includes(this.status);
  }

  /**
   * Whether this was a response that failed.
   * @return {boolean}
   */
  isFailed() {
    return [JSendResponse.STATUS_FAIL].includes(this.status);
  }

  /**
   * Whether this was a response that is an error.
   * @return {boolean}
   */
  isError() {
    return [JSendResponse.STATUS_ERROR].includes(this.status);
  }

  /**
   *
   * @return {string}
   */
  getMessage() {
    return this.message;
  }

  /**
   *
   * @return {null|Record<String, any}}
   */
  getData() {
    return this.data;
  }

  /**
   *
   * @return {number}
   */
  getCode() {
    return this.code;
  }
}
