import ApplicationConstants from 'constants/ApplicationConstants';
import APIService from 'services/APIService';

import LocalStorageService from '../services/LocalStorageService';

/**
 * Options
 *
 * This object will offer us methods that will help us write shorter code, for example the method
 * getAPIService.
 */
const Actions = {
  /**
   * Helper method to return the APIService class, this class is used to actually call API methods
   * and return a promise that handles the response.
   * @return {APIService}
   */
  getAPIService() {
    return new APIService(
      ApplicationConstants.APPLICATION_API_HOST,
      LocalStorageService.getItem('token', null),
    );
  },
};

export default Actions;
