import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

export default class SearchApi extends APIEndpoint {
  /**
   * @param {string} query
   * @param {AbortSignal|undefined} query
   * @return {Promise<JSendResponse>}
   */
  autocomplete(query, signal) {
    const url = `search/autocomplete?query=${query}`;
    return new JSendRequest(this.getBaseUrl(), this.getToken(), signal).request(url);
  }

  /**
   * @param {string} query
   * @param {string} name
   * @param {string} sort
   * @param {number} page (Optional) Current page number, defaults to 1
   * @param {number} maxPerPage (Optional) Max results per page, defaults to 10
   * @return {Promise}
   */
  search(query, name, sort = 'relevance', page = 1, maxPerPage = 10) {
    const url = `search?query=${query}&filter=type::${name}&sort=${sort}&page=${page}&maxPerPage=${maxPerPage}`;
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }
}
