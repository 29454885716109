import DataModel, { PropTypes } from 'models/base/DataModel';

import Actuality from './Actuality';
import PartialConsideration from './PartialConsideration';
import Source from './Source';
import Tool from './Tool';

/**
 * Consideration
 */
export default class Consideration extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    url: PropTypes.string,
    canvasSections: PropTypes.string,
    isDeleted: PropTypes.bool,
    isManual: PropTypes.bool,
    createdDateTime: PropTypes.string,
    position: PropTypes.number,
    topic: PropTypes.shape({
      ...DataModel.propTypes,
      name: PropTypes.string,
      isDeleted: PropTypes.bool,
      isManual: PropTypes.bool,
      createdDateTime: PropTypes.string,
    }),
    partialConsiderations: PropTypes.arrayOf(PropTypes.shape(PartialConsideration.propTypes)),
    actuality: PropTypes.arrayOf(PropTypes.shape(Actuality.propTypes)),
    sources: PropTypes.arrayOf(PropTypes.shape(Source.propTypes)),
    ownSources: PropTypes.arrayOf(PropTypes.shape(Source.propTypes)),
    tools: PropTypes.arrayOf(PropTypes.shape(Tool.propTypes)),
  };

  /**
   * Topic name.
   * @type {string}
   */
  name;

  /**
   * Topic name.
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  canvasSections;

  /**
   * @type {boolean}
   */
  isDeleted;

  /**
   * @type {boolean}
   */
  isManual;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {number}
   */
  position;

  /**
   * @type {Object}
   */
  topic;

  /**
   * @var {array}
   */
  partialConsiderations;

  /**
   * @var {array}
   */
  actuality;

  /**
   * @var {Source[]}
   */
  sources;

  /**
   * @var {Source[]}
   */
  ownSources;

  /**
   * @var {Tool[]}
   */
  tools;

  /**
   *
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   *
   * @returns {string}
   */
  getCanvasSections() {
    return this.canvasSections;
  }

  /**
   * @returns {string}
   */
  getCanvasSectionsDisplayName() {
    const canvasSections = this.getCanvasSections();
    return canvasSectionsTypes[canvasSections]?.singular || canvasSections;
  }

  /**
   *
   * @returns {boolean}
   */
  getIsDeleted() {
    return this.isDeleted;
  }

  /**
   *
   * @returns {boolean}
   */
  getIsManual() {
    return this.isManual;
  }

  /**
   *
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   *
   * @returns {number}
   */
  getPosition() {
    return this.position;
  }

  /**
   *
   * @returns {Object}
   */
  getTopic() {
    return this.topic;
  }

  /**
   *
   * @returns {Array}
   */
  getPartialConsiderations() {
    if (this.partialConsiderations === undefined) {
      this.partialConsiderations = [];
    }

    return this.partialConsiderations;
  }

  /**
   *
   * @returns {Array}
   */
  getActuality() {
    if (this.actuality === undefined) {
      this.actuality = [];
    }

    return this.actuality;
  }

  /**
   *
   * @returns {Source[]}
   */
  getSources() {
    if (this.sources === undefined) {
      this.source = [];
    }

    return this.sources;
  }

  /**
   *
   * @returns {Source[]}
   */
  getOwnSources() {
    if (this.ownSources === undefined) {
      this.ownSources = [];
    }

    return this.ownSources;
  }

  /**
   *
   * @returns {Tool[]}
   */
  getTools() {
    if (this.tools === undefined) {
      this.tools = [];
    }

    return this.tools;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'partialConsiderations') {
      value = PartialConsideration.createInstancesByArray(value);
    } else if (fieldName === 'actuality') {
      value = Actuality.createInstancesByArray(value);
    } else if (fieldName === 'tools') {
      value = Tool.createInstancesByArray(value);
    } else if (fieldName === 'ownSources') {
      value = Source.createInstancesByArray(value);
    }

    return super.set(fieldName, value);
  }
}

export const canvasSectionsTypes = {
  facts: {
    name: 'facts',
    singular: 'Feit',
    plural: 'Feiten',
    color: '#99d5f1',
  },
  considerations: {
    name: 'considerations',
    singular: 'Overweging',
    plural: 'Overwegingen',
    color: '#fff199',
  },
  results: {
    name: 'results',
    singular: 'Beslissing',
    plural: 'Beslissingen',
    color: '#cfe4a8',
  },
};
