import type { NotificationUserType } from 'components/notificationcenter/types';
import { formatRFC3339 } from 'date-fns';
import JSendRequest from 'services/JSendRequest';

import APIEndpoint from './APIEndpoint';

/**
 * Notifications API
 */
export default class NotificationsApi extends APIEndpoint {
  getNotificationSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`notification/settings`);
  }

  modifyNotificationUserType(type: string, item: NotificationUserType) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `notification/settings/${type}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      item,
    );
  }

  getNotificationUnreadCount(lastRequestDate: Date | undefined) {
    const url = `notification/count_unread${
      lastRequestDate ? `?lastTimestamp=${encodeURIComponent(formatRFC3339(lastRequestDate))}` : ''
    }`;
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url.toString());
  }

  getNotifications(page = 1) {
    const url = this.buildUrl(`notification`, [], '', page, 10);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  markAllRead() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `notification/all_read`,
      JSendRequest.REQUEST_METHOD_POST,
    );
  }

  dismissNotification(notificationId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `notification/${notificationId}/dismiss`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  setRead(notificationId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `notification/${notificationId}/read`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }
}
