import JSendRequest from 'services/JSendRequest';

import APIEndpoint from './APIEndpoint';

export default class SharePointApi extends APIEndpoint {
  getSites() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'sharepoint/sites',
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  getDrives(siteId: string) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `sharepoint/sites/${siteId}/drives`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }
}
