import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useCallback, useMemo } from 'react';

import { normalizeNotificationItem } from '../normalizer';
import type { NotificationItem } from '../types';
import { useNotificationUnreadCount } from './useNotificationUnreadCount';

const REFETCH_INTERVAL = 1000 * 60; // every minute

type useNotificationsParams = {
  enabled: boolean;
};

export const useNotifications = ({ enabled = true }: useNotificationsParams) => {
  const { refetch: refetchUnread } = useNotificationUnreadCount();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) =>
      Actions.getAPIService()
        .notifications()
        .getNotifications(pageParam)
        .then((data) =>
          normalizeOverviewResults(data.getData(), 'notifications', normalizeNotificationItem),
        ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.paging.totalPages !== lastPage.paging.page ? lastPage.paging.nextPage : undefined,
    refetchIntervalInBackground: true,
    refetchInterval: REFETCH_INTERVAL,
    enabled,
  });

  const reloadNotifications = useCallback(async () => {
    await refetch();
    await refetchUnread();
  }, [refetch, refetchUnread]);

  // region mutations
  const dismissNotificicationMutate = useMutation<any, unknown, NotificationItem>({
    mutationFn: (notification) =>
      Actions.getAPIService().notifications().dismissNotification(notification.id),
    onSuccess: async () => {
      await reloadNotifications();
    },
  });

  const markAllAsReadMutate = useMutation({
    mutationFn: () => Actions.getAPIService().notifications().markAllRead(),
    onSuccess: async () => {
      await reloadNotifications();
    },
  });
  // endregion

  // region memos
  const notifications = useMemo(() => {
    return data?.pages
      .map(({ results }) => results)
      .reduce((prevValues, currentValues) => [...prevValues, ...currentValues]);
  }, [data?.pages]);

  const hasMoreResults = useMemo(() => {
    const paging = data?.pages.at(-1)?.paging;
    if (!paging) {
      return false;
    }

    return paging.nextPage !== paging.page && paging.numberOfResults > (notifications ?? []).length;
  }, [data?.pages, notifications]);
  // endregion

  // region callback that are exported
  const dismissNotification = useCallback(
    (notification: NotificationItem) => {
      return dismissNotificicationMutate.mutateAsync(notification);
    },
    [dismissNotificicationMutate],
  );

  const markAllAsRead = useCallback(() => {
    return markAllAsReadMutate.mutateAsync();
  }, [markAllAsReadMutate]);
  // endregion

  return {
    notifications: notifications ?? [],
    isLoading,
    isFetchingNextPage,
    hasMoreResults,
    fetchNextPage,
    dismissNotification,
    markAllAsRead,
  };
};
