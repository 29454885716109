import type React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { authRedirectGetLocation, authRedirectSaveLocation } from '.';

const AuthRedirectSaveState: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // save to current location to the localstorage
    authRedirectSaveLocation(location);

    // redirect to authenticate container
    navigate('/authenticate');
  }, [location, navigate]);

  return null;
};

const AuthRedirectLoadState: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(authRedirectGetLocation(location));
  }, [navigate, location]);
  return null;
};

export { AuthRedirectLoadState, AuthRedirectSaveState };
