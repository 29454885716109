import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { useEffect, useState } from 'react';

import { normalizeNotificationUnreadResponse } from '../normalizer';
import type { NotificationUnreadResponse } from '../types';

const REFETCH_INTERVAL = 1000 * 60; // every minute

export const useNotificationUnreadCount = () => {
  const [lastVisitTime, setLastVisitTime] = useState<Date>(new Date());
  const notificationCountQuery = useQuery<NotificationUnreadResponse>({
    queryKey: ['notifications-count'],

    queryFn: () =>
      Actions.getAPIService()
        .notifications()
        .getNotificationUnreadCount(lastVisitTime)
        .then((data) => normalizeNotificationUnreadResponse(data.getData())),

    refetchIntervalInBackground: true,
    refetchInterval: REFETCH_INTERVAL,
  });

  useEffect(() => {
    if (notificationCountQuery.dataUpdatedAt) {
      setLastVisitTime(new Date(notificationCountQuery.dataUpdatedAt));
    }
  }, [notificationCountQuery.dataUpdatedAt]);

  return {
    count: notificationCountQuery.data?.unread ?? 0,
    unreadNotifications: notificationCountQuery.data?.unreadNotifications ?? [],
    refetch: notificationCountQuery.refetch,
  };
};
