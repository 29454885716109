/**
 * Api service
 */
export default class APIEndpoint {
  /**
   * @var {APIService}
   */
  apiService;

  /**
   * Constructor
   *
   * @param {APIService} apiService
   */
  constructor(apiService) {
    this.apiService = apiService;
  }

  // #region getters and setters
  /**
   * Get token
   *
   * @returns {null|string}
   */
  getToken() {
    return this.apiService.getToken();
  }

  /**
   * Get base url
   *
   * @returns {string}
   */
  getBaseUrl() {
    return this.apiService.getBaseUrl();
  }

  // #endregion

  // #region helper methods to build an url with searching, filtering, sorting and paging.
  /**
   * Helper to build an url with search, filters, sorting, paging and limiting.
   *
   * @param {string} url
   * @param {object} filters
   * @param {string} sort
   * @param {number|string} page
   * @param {number|string} maxPerPage
   * @returns {string}
   */
  buildUrl(url, filters = [], sort = '', page = 1, maxPerPage = 10) {
    const query = [`page=${parseInt(page, 10)}`];

    if (maxPerPage) {
      query.push(`maxPerPage=${parseInt(maxPerPage, 10)}`);
    }

    if (sort.length) {
      query.push(`sort=${escape(sort)}`);
    }

    if (filters.length) {
      // build filter query; name::value,value,value
      query.push(`filter=${this.buildFilterQueryValue(filters)}`);
    }

    return `${url}?${query.join('&')}`;
  }

  buildFilterQueryValue(filters = []) {
    // convert all filters to an array with values indexed by filter name.
    const queryFilters = {};
    filters.forEach((filter) => {
      queryFilters[filter.name] = queryFilters[filter.name] || [];
      queryFilters[filter.name].push(escape(filter.value));
    });

    // build filter query; name::value,value,value
    return Object.keys(queryFilters)
      .map((filterName) => {
        return `${filterName}::${queryFilters[filterName].join(',')}`;
      })
      .join('|');
  }

  // #endregion
}
