import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * ConsiderationsApi
 */
export default class ConsiderationsApi extends APIEndpoint {
  /**
   * Get own considerations
   *
   * @param {array} filters
   * @param {string} sort
   * @param {number} page
   * @param {number} limit
   * @returns {Promise}
   */
  getOwnConsiderations(filters = [], sort = '', page = 1, limit = 10) {
    const url = this.buildUrl('own-templates/considerations', filters, sort, page, limit);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Get consideration details
   *
   * @param {string} considerationId
   */
  considerationDetails(considerationId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `considerations/${considerationId}`,
    );
  }

  /**
   * Create consideration
   *
   * @param {object} values
   */
  createConsideration(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'considerations',
      JSendRequest.REQUEST_METHOD_POST,
      this.convertConsiderationPostValues(values),
    );
  }

  /**
   * Update consideration
   *
   * @param {string} considerationId
   * @param {object} values
   */
  updateConsideration(considerationId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `considerations/${considerationId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      this.convertConsiderationPostValues(values),
    );
  }

  /**
   * Delete consideration
   *
   * @param {string} considerationId
   */
  deleteConsideration(considerationId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `considerations/${considerationId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Convert consideration form values to value used in an API post.
   *
   * @param values
   * @returns {*}
   */
  convertConsiderationPostValues(values) {
    const valuesObj = { ...values };
    if (values.topic) {
      valuesObj.topic = values.topic.value;
    }

    if (values.canvasSections) {
      valuesObj.canvasSections = values.canvasSections.value;
    }

    return valuesObj;
  }
}
