import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Template attachment folder API
 */
export default class TemplateAttachmentFolderApi extends APIEndpoint {
  /**
   * Overview
   *
   * @param {array} filters
   * @param {string} sort
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise}
   */
  overview(filters = [], sort = '', page = 1, pageSize = 10) {
    const url = this.buildUrl('template_attachment_folder', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Details
   *
   * @param {string} id
   * @returns {Promise}
   */
  details(id) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_attachment_folder/${id}`,
    );
  }

  /**
   * Create
   *
   * @param {object} values
   * @returns {Promise}
   */
  create(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'template_attachment_folder',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Modify
   *
   * @param {string} id
   * @param {object} values
   * @returns {Promise}
   */
  modify(id, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_attachment_folder/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete
   *
   * @param {string} id
   * @returns {Promise}
   */
  delete(id) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_attachment_folder/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get settings
   *
   * @returns {Promise}
   */
  getSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'template_attachment_folder/admin',
    );
  }

  /**
   * Save settings
   *
   * @param {object} values
   * @returns {Promise}
   */
  saveSettings(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'template_attachment_folder/admin',
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }
}
