import moment from 'moment';

// phaseText constants.
export const phasesText = {
  inventory: 'Inventarisatie',
  analysis: 'Analyse',
  advice: 'Advies',
  execution: 'Uitvoering',
};

/**
 * Get item from array of object by looking up the value property of the object.
 *
 * @param {string|undefined} value
 * @param {Array} items
 * @returns {object|null}
 */
export const getObjectFromValue = (value, items) => {
  let retValue = null;

  for (let index = 0; index < items.length; index++) {
    if (items[index].value === value) {
      retValue = items[index];
      break;
    }
  }
  return retValue;
};

/**
 * Convert a key-value object to filter array that de API wants.
 *
 * @param {Object} filters
 */
export const objectToFilterArray = (filters) => {
  const filterObj = { ...filters };

  const filterKeys = Object.keys(filterObj);
  filterKeys.forEach((filterKey) => {
    if (filterObj[filterKey] === null || filterObj[filterKey] === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete filterObj[filterKey];
      return;
    }

    if (filterObj[filterKey] instanceof Array && filterObj[filterKey].length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete filterObj[filterKey];
    }
  });

  // create filter array from object.
  const filterArr = [];

  Object.keys(filterObj).forEach((key) => {
    filterArr.push({ name: key, value: filterObj[key] });
  });

  return filterArr;
};

/**
 * Filter object and return only a new object with the excepted keys back.
 *
 * @param {object} object
 * @param {array} exceptKeys
 * @returns {string[]}
 */
export const filterObjectExcept = (object, exceptKeys) => {
  return Object.keys(object)
    .filter((key) => exceptKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

/**
 * @param {string} inDate
 * @param {string} dateWithTime
 * @return {string}
 */
export function addTimeToDate(inDate, dateWithTime) {
  const retValue = moment(inDate);
  const dateTime = moment(dateWithTime);
  retValue.hours(dateTime.hours());
  retValue.minutes(dateTime.minutes());
  retValue.seconds(dateTime.seconds());

  return retValue.toString();
}

/**
 * Create string date so it can be used for sorting in Titan tables.
 *
 * @param {string} inDate
 * @returns {string}
 */
export function createDateSort(inDate) {
  const retValue = moment(inDate);

  return retValue.format();
}
