import dayjs from 'dayjs';
import { normalizePaging } from 'entity/base/normalizer';
import type { OverviewResults } from 'entity/base/types';

export function normalizeOverviewResults<T>(
  results: any,
  resultsKey: string,
  itemNormalize: (item: any) => T,
): OverviewResults<T> {
  return {
    paging: normalizePaging(results._paging),
    results: results[resultsKey]?.map(itemNormalize) ?? [],
  };
}

export const convertToDate = (value: any): Date | undefined =>
  value ? dayjs(value).toDate() : undefined;

export function normalizeMap<T>(value: any[] | undefined, normalizer: (item: any) => T): T[] {
  return value ? value.map(normalizer) : [];
}
