import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * ContextItem
 */
class ContextItem extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    contextType: PropTypes.string,
    hasAttachment: PropTypes.bool,
    isBerkleyModel: PropTypes.bool,
    berkleyModelId: PropTypes.string,
  };

  /**
   * Human readable name of the context item.
   * @type {string}
   */
  name;

  /**
   * Short optional description of the context item.
   * @type {string}
   */
  description;

  /**
   * This represents the actual type of the context item, from this you can determine icon types, etc.
   * @type {string}
   */
  type;

  /**
   * External url location where the context item document can be found.
   * @type {string}
   */
  url;

  /**
   * @type {boolean}
   */
  hasAttachment;

  /**
   * @type {boolean}
   */
  isBerkleyModel;

  /**
   * @type {string}
   */
  berkleyModelId;

  /**
   * Human readable name of the context item.
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Short optional description of the context item.
   * @returns {string}
   */
  getDescription() {
    return this.description;
  }

  /**
   * This represents the actual type of the context item, from this you can determine icon types, etc.
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   * External url location where the context item document can be found.
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   * Return the context type of this tool, each tool has a type and context type. The context type is the context group
   * the tool belongs to.
   * @return {string}
   */
  getContextType() {
    return this.contextType;
  }

  /**
   * Has attachment
   *
   * @returns {boolean}
   */
  getHasAttachment() {
    return this.hasAttachment;
  }

  /**
   * @returns {boolean}
   */
  getIsBerkleyModel() {
    return this.isBerkleyModel;
  }

  /**
   * @returns {string}
   */
  getBerkleyModelId() {
    return this.berkleyModelId;
  }
}

export default ContextItem;
